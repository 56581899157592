function createLayers(smap) {

    var exports = {
        // array for map with vectorlayer for features
        createMapArrayAndSendToLayers: function(layerConfigs, me) {
            var layers = layerConfigs.map(function (layerConfig) {
                try {
                var layer = exports.resolveLayer(layerConfig, me);

                if (layer.placement.backgroundlayer && layerConfig.showhide) {
                    me.variables.currentBackgroundLayer = layer;
                }

                return layer;
                }catch(ex){
                    console.error(ex);
                    return null;
                }
            }).filter(layer => layer != null);

            if (me.variables.config.clusterOptions.combinedClusterLayer) {
                var combined = me.variables.combinedClusterLayer = me.basicMap.createCombinedClusterLayer(layers, me.variables.featureArray, me);

                // Handle showing either the combined cluster layer or the individual layers
                combined.on('change:visible', function () {
                    var combinedVisible = combined.getVisible();

                    me.variables.featureLayers.forEach(function (layer) {
                        var source = layer.cluster
                            ? layer.getSource().getSource()
                            : layer.getSource();

                        layer.setVisible(combinedVisible ? false : source.get('enabled') && source.get('isActive'));
                    });
                });

                layers.push(me.variables.combinedClusterLayer);
            }

            me.structureMap.GetLayerControl(me, layers);

            return layers;
        },

        resolveLayer: function (config, me) {
            var source, clusterSource,
                layer,
                popup, template,
                position = config.placement.backgroundlayer ? 99 : 999,
                activateLayer = function (visible) {
                    layer.setVisible(visible);
                };

            switch (config.type) {
                case 'tile':
                    source = new ol.source.XYZ({ url: config.url });
                    layer = new ol.layer.Tile({
                        source: source,
                        zIndex: position,
                        opacity: config.opacity,
                        visible: config.showhide,
                    });

                    break;

                case 'tileWMS':
                    var tileGrid;

                    if (typeof config.parameters === 'string' && config.parameters.length > 0) {
                        try {
                            config.parameters = JSON.parse('{' + config.parameters + '}');
                        } catch (ex) {
                            config.parameters = null;
                        }
                    }

                    if (config.tilegrid) {
                        tileGrid = new ol.tilegrid.TileGrid({
                            extent: config.tilegrid.extent || null,
                            origin: config.tilegrid.origin || null,
                            tileSize: config.tilegrid.tilesize || undefined,
                            resolutions: config.tilegrid.resolutions || undefined
                        });
                    }

                    source = config.default
                        ? new ol.source.OSM()
                        : new ol.source.TileWMS({
                            url: config.url,
                            params: config.parameters,
                            serverType: config.servertype || undefined,
                            crossOrigin: 'anonymous',
                            tileGrid: tileGrid || null
                        });

                    layer = new ol.layer.Tile({
                        source: source,
                        zIndex: position,
                        opacity: config.opacity,
                        visible: config.showhide
                    });

                    popup = config.popup || {};

                    if (popup.template) {
                        try {
                            smapMustache.parse(popup.template);
                            template = popup.template;
                        } catch (ex) {
                            console.log('Could not construct template');
                        }

                        source.setProperties({
                            template: function (data) {
                                return template ? smapMustache.render(template, data) : '';
                            }
                        });

                        me.variables.clickableWMS.push(layer);
                    }

                    break;
                case 'wmts':

                    const parser = new ol.format.WMTSCapabilities();
                    const result = parser.read(config.capabilitiesXml);
                      const wmtsOptions =  ol.source.WMTS.optionsFromCapabilities(result, {
                        layer: config.layer
                      });
                      source = new ol.source.WMTS(wmtsOptions);
                      layer =  new ol.layer.Tile({
                        opacity: config.opacity,
                        visible: config.showhide,
                        source: source
                      });                    

                break;
                case 'tileWFS':
                        source = new ol.source.Vector({
                            format: new ol.format.GeoJSON(),
                            url: function (extent) {
                                return config.url;
                            }
                        });

                        var layer = new ol.layer.Vector({
                            source: vectorsource,
                            opacity: config.opacity,
                            visible: config.showhide,
                            zIndex: position,
                            style: new ol.style.Style({
                                stroke: new ol.style.Stroke({
                                    color: 'rgb(196,0,100,1)',
                                    width: 2
                                })
                            })
                        });

                    break;

                case 'tileArcGis':
                    source = new ol.source.TileArcGISRest({
                        url: config.url,
                        crossOrigin: 'Anonymous'
                    });

                    layer = new ol.layer.Tile({
                        source: source,
                        opacity: config.opacity,
                        visible: config.showhide,
                        zIndex: position,
                    });

                    break;

                case 'tileRestArcGis':
                    source = new ol.source.XYZ({
                        url: config.url,
                        attributions: config.attributions
                    });

                    layer = new ol.layer.Tile({
                        source: source,
                        opacity: config.opacity,
                        visible: config.showhide,
                        zIndex: position,
                    });

                    break;

                case 'geoJSON':
                case 'WFS-geoJSON':
                    config.clusterlayer = !!config.clusterlayer;

                    popup = config.popup || {};

                    if (popup.template) {
                        try {
                            smapMustache.parse(popup.template);
                            template = popup.template;
                        } catch (ex) {
                            console.log('Could not construct template');
                        }
                    }

                    // vector source for POI:s with featuresJson as features
                    source = clusterSource = new ol.source.Vector({
                        format: new ol.format.GeoJSON(),
                        loader: config.type === 'geoJSON'
                            ? function() {
                                source.addFeatures(config.geojson.features.map(function (obj) {
                                    obj.properties.geometry = new ol.geom.Point(obj.geometry.coordinates);
                                    var feature = new ol.Feature(obj.properties);
                                    feature.source = source;

                                    return feature;
                                }));
                            }
                            : function (extent/*, resolution, projection*/) {
                                var xhr = new XMLHttpRequest();
                                xhr.open('GET', config.url);
                                xhr.onerror = function () { source.removeLoadedExtent(extent); };
                                xhr.onload = function () {
                                    if (xhr.status == 200) {
                                        var features = source.getFormat().readFeatures(xhr.responseText);
                                        features.forEach(function (feature) {
                                            feature.source = source;
                                            feature.setProperties({
                                                poiType: config.poiType,
                                                name: popup.nameProperty ? feature.get(popup.nameProperty) : '',
                                                fullContent: template ? smapMustache.render(template, feature.getProperties()) : ''
                                            });
                                        });
                                        source.addFeatures(features);
                                    } else {
                                        xhr.onerror();
                                    }
                                };
                                xhr.send();
                            }
                    });

                    me.variables.featureArray.push(source);

                    if (config.clusterlayer) {
                        // and wrap it in a cluster source
                        clusterSource = new ol.source.Cluster({
                            distance: parseInt(me.variables.config.clusterOptions.clusterRadius || 70),
                            source: source
                        });

                        clusterSource.set('name', config.name);
                        config.legend && config.legend.icon && clusterSource.set('icon', config.legend.icon);
                    }

                    // vectorlayer for map with features of POI:s
                    layer = new ol.layer.Vector({
                        source: clusterSource,
                        opacity: config.opacity,
                        visible: config.showhide && !me.variables.config.clusterOptions.combinedClusterLayer,
                        style: me.advancedMap.styleFunction,
                        zIndex: config.placement.backgroundlayer ? 10 : 999
                    });

                    layer.cluster = config.clusterlayer;
                    layer.initialVisibility = config.showhide;

                    me.variables.featureLayers.push(layer);

                    if (me.variables.config.clusterOptions.combinedClusterLayer) {
                        activateLayer = function (visible) {
                            if (!me.variables.combinedClusterLayer.getVisible()) {
                                layer.setVisible(visible);
                            }
                        };
                    }

                    break;

                default:
                    return;
            }

            // set name on layer and source
            layer.set('name', config.name);
            source.set('name', config.name);

            source.set('enabled', true);
            source.set('isActive', config.showhide);

            // If there is a legend with an icon add it to the source
            config.legend && config.legend.icon && source.set('icon', config.legend.icon);

            // adds placement object to map obj, so that sorting on group name and placement is possible
            layer.placement = config.placement;
            layer.minZoom = config.minZoom || -1;
            layer.maxZoom = config.maxZoom || Infinity;

            layer.checkZoomConstraints = function (currentZoom) {
                var enabled = currentZoom >= layer.minZoom && currentZoom <= layer.maxZoom;
                source.set('enabled', enabled);
                activateLayer(enabled && source.get('isActive'));
            };

            config.layerId = layer.ol_uid;

            // observe changes on the source to handle if the layer should be visible or not
            source.on('change:isActive', function () { activateLayer(source.get('isActive')) });

            return layer;
        }
    };

    return exports;
}
