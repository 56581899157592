(function (scope) {

    function initEnvironment(){
        var smap = {
            variables: {
                map: null,
                config: null,
                wrapperDiv: null,
                container: null,
                closer: null,
                overlay: null,
                view: null,
                geolocation: null,
                positionFeature: null,
                searchPositionFeature: null,
                mapArray: null,
                clickableWMS: [],
                proj3011: null,
                clusterZoom: null,
                closeToZoom: null,
                minZoom: null,
                maxZoom: null,
                singleZoom: null,
                resolutions: null,
                legendShowOnStart: null,
                featureArray: [],
                featureLayers: [],
                changeToCombinedLayerLevel: 12,
                combinedClusterLayer: null,
                combinedClusterPosition: null,
                snap: null,
                modify: [],
                tools: {},
                drawLayer: null,
                latestDrawFeature: null,
                clusterZoomFeatureAmount: undefined,
                clusterCount: true,
                clusterSortPopupResultByLayer: true,
                clusterSortPopupResultByName: true,
                backgroundLayerAmount: 0,
            }
        };

        /* START: PROTOTYPE OF CONSTANTS IN MAP */

        smap.constants = function(){
        };
        smap.constants.prototype = {

            // Constants
            // --------------------
            MAX_SEARCH_ITEM_COUNT_DISLAY: 7,
            EVENT_PREFIX_WRAPPER: null,
            EVENT_PREFIX: null,
            POPUP_CLASS: ".popup",
            POPUP_CLOSER: ".s-map-popup-close",
            POPUP_DELETE: ".s-map-popup-delete",
            POPUP_INNER: ".s-map-popup",
            ZOOM_IN_BTN_CLASS: ".s-map-btn-zoom-in",
            ZOOM_OUT_BTN_CLASS: ".s-map-btn-zoom-out",
            BODY_FULLSCREEN_CLASS: "s-map-fullscreen",
            BODY_FULLSCREEN_SEARCH_CLASS: "s-map-fullscreen-search",
            BODY_FULLSCREEN_MAP_CLASS: "s-map-body-fullscreen",
            MAP_VIEWPORT_FULLSCREEN: "s-map-viewport-fullscreen",
            FULLSCREEN_BTN_CLASS: ".s-map-btn-fullscreen",
            GEOLOCATION_BTN_CLASS: ".s-map-btn-my-position",
            TOOLS_BTN_CLASS: ".s-map-panel-nav-tools",
            SEARCH_FORM_ID: "#s-map-search-form",
            SEARCH_BTN_ID: "#s-map-search-button",
            SEARCH_TEXT_INPUT_ID: "#s-map-search-input",
            SEARCH_BTN_RESULT_CLASS: ".s-map-panel-nav-searchresult",
            SEARCH_BEARD_CLASS: ".s-map-autocomplete",
            SEARCH_RESET_CLASS: ".s-map-search-reset",
            SHARE_BTN_CLASS: ".s-map-panel-nav-share",
            LEGEND_BTN_CLASS: ".s-map-panel-nav-legend",
            LAYER_BTN_CLASS: ".s-map-panel-nav-layers",
            LAYERS_BTN_CLASS: ".s-map-panel-nav-layers",
            SCALELINE_CLASS: ".ol-viewport",
            LAYER_TOGGLE_BTN_CLASS: ".s-map-switch-layer",
            LEGEND_CONTAINER: "#s-map-legend",
            LEGEND_CONTAINER_DIV: "#s-map-panel-legend",
            SEARCH_CONTAINER_DIV: "#s-map-panel-searchresult",
            SHARE_CONTAINER_DIV: "#s-map-panel-share",
            TOOLS_CONTAINER_DIV: "#s-map-panel-tools",
            MAP_LAYERS_BTN_CLASS: '.s-switch-map-layers',
            LAYER_BTN_HEADER: '#s-map-layers-header',
            SEARCH_INPUT_CONTAINER: '.s-map-search-autocomplete',

            // no type prefix
            // sets in html factory
            GEOLOCATOR_BTN_ACTIVE_CLASS: "active-geolocator",
            GEOLOCATION_CLOSE_BTN_ACTIVE_CLASS: "active-close-to",

            // --------------------
        }

        // Variables
        // --------------------

        smap.imageMap = imageStruct(smap);
        smap.structureMap = mapStruct(smap);
        smap.advancedMap = {};
        smap.basicMap = new basicMap(smap);

        // IE 10 Object.assign
        if (typeof Object.assign != 'function') {
            // Must be writable: true, enumerable: false, configurable: true
            Object.defineProperty(Object, "assign", {
              value: function assign(target, varArgs) { // .length of function is 2
                'use strict';
                if (target == null) { // TypeError if undefined or null
                  throw new TypeError('Cannot convert undefined or null to object');
                }

                var to = Object(target);

                for (var index = 1; index < arguments.length; index++) {
                  var nextSource = arguments[index];

                  if (nextSource != null) { // Skip over if undefined or null
                    for (var nextKey in nextSource) {
                      // Avoid bugs when hasOwnProperty is shadowed
                      if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                        to[nextKey] = nextSource[nextKey];
                      }
                    }
                  }
                }
                return to;
              },
              writable: true,
              configurable: true
            });
          }

        // BASICMAP
        Object.assign(smap.basicMap, createMap(smap));
        Object.assign(smap.basicMap, createLayers(smap));
        Object.assign(smap.basicMap, createClusterLayer(smap));
        Object.assign(smap.basicMap, createMapEvents(smap));
        Object.assign(smap.basicMap, sortData(smap));

        // ADVANCEDMAP
        Object.assign(smap.advancedMap, createPopup(smap));
        Object.assign(smap.advancedMap, createTools(smap));
        Object.assign(smap.advancedMap, createInteraction(smap));
        //Object.assign(smap.advancedMap, createPopup(smap));

        // IMAGEMAP

        // STRUCTUREMAP

        smap.event = {
            delete: function(ev) {
                console.log({delete: ev});
            },
            change: function(ev, layer) {
                console.log({feature: ev, layer: layer});
            },
            modify: function(ev) {
                console.log({modify: ev});
            },
            move: function(ev) {
                console.log({move: ev});
            },
            add: function(ev) {
                console.log({add: ev});
            },
            drawend: function(ev) {
                console.log({drawend: ev});
            },
        };

        return smap;
    }


        // --------------------

    /**
     *  _init
     */
    function _init(config, target, targetWrapper) {
        var me = initEnvironment(),
            wrapperDiv,
            targetDiv;

        if (targetWrapper instanceof Element) {
            wrapperDiv = targetWrapper;
            targetWrapper = '.' + targetWrapper["className"];
        } else {
            wrapperDiv = document.querySelector(targetWrapper);
        }

        targetDiv = wrapperDiv.querySelector(target);
        targetDiv.setAttribute('tabindex', '0');
        targetDiv.setAttribute('aria-label', 'Karta, navigeras med piltangenter. Zooma i karta med plus och minustangenter.');
        targetDiv.setAttribute('role', 'application');

        if (!wrapperDiv || !targetDiv) {
            console.error('Missing Params: Need target div and target div container. (data, target containing map, wrapper containing map)');
            return null;
        }

        me.variables.config = config;

        Number.isInteger = Number.isInteger || function(value) {
            return typeof value === "number" &&
                isFinite(value) &&
                Math.floor(value) === value;
        };

        for (var layer in me.variables.config.layers) {
            if (me.variables.config.layers[layer].placement) {
                if (me.variables.config.layers[layer].placement.backgroundlayer === true) {
                me.variables.backgroundLayerAmount++
                }
            }
        }

       if (config.zoomLevel) {
            me.variables.singleZoom = (Number.isInteger(config.zoomLevel.singelPopupZoomLevel)) ? config.zoomLevel.singelPopupZoomLevel : null;
            me.variables.closeToZoom = (Number.isInteger(config.zoomLevel.geolocationZoomLevel)) ? config.zoomLevel.geolocationZoomLevel : null;
            me.variables.minZoom = (Number.isInteger(config.zoomLevel.minZoom)) ? config.zoomLevel.minZoom : 1;
            me.variables.maxZoom = (Number.isInteger(config.zoomLevel.maxZoom)) ? config.zoomLevel.maxZoom : 22;
        }
        if(config.clusterOptions) {
            me.variables.clusterZoom = (Number.isInteger(config.clusterOptions.clusterPopupZoomLevel)) ?
                config.clusterOptions.clusterPopupZoomLevel :
                    null;

            me.variables.clusterZoomFeatureAmount = (Number.isInteger(config.clusterOptions.clusterZoomFeatureAmount)) ?
                config.clusterOptions.clusterZoomFeatureAmount :
                    undefined;

            (me.variables.config.clusterOptions.clusterZoomLevelSwitch) ?
                me.variables.changeToCombinedLayerLevel = me.variables.config.clusterOptions.clusterZoomLevelSwitch :
                    me.variables.changeToCombinedLayerLevel = 12;

            (typeof me.variables.config.clusterOptions.clusterCount === "boolean") ?
                me.variables.clusterCount = me.variables.config.clusterOptions.clusterCount :
                    null;

            (typeof me.variables.config.clusterOptions.clusterSortPopupResultByLayer === "boolean") ?
                me.variables.clusterSortPopupResultByLayer = me.variables.config.clusterOptions.clusterSortPopupResultByLayer :
                    null;

            (typeof me.variables.config.clusterOptions.clusterSortPopupResultByName === "boolean") ?
                me.variables.clusterSortPopupResultByName = me.variables.config.clusterOptions.clusterSortPopupResultByName :
                    null;
        }
        me.constants.prototype.EVENT_PREFIX = target;
        me.constants.prototype.EVENT_PREFIX_WRAPPER = targetWrapper;

        // get element where this map lives
        me.variables.wrapperDiv = wrapperDiv;
        me.targetDiv = targetDiv;
        // get popup element to be able to insert data
        me.variables.container = me.variables.wrapperDiv.querySelector(me.constants.prototype.POPUP_CLASS);
        me.variables.closer = me.variables.wrapperDiv.querySelector(me.constants.prototype.POPUP_CLOSER);

        // creating the overlay
        me.variables.overlay = me.advancedMap.createOverlay(me.variables.wrapperDiv);

        // hides button on map based on the config given
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.GEOLOCATION_BTN_CLASS)) ?
        (config.buttonVisibility.geolocationVisibility) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.GEOLOCATION_BTN_CLASS).classList.toggle('hide') : null : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.TOOLS_BTN_CLASS)) ?
        (config.buttonVisibility.tooltab) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.TOOLS_BTN_CLASS).classList.toggle('hide') : null : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.SEARCH_BTN_RESULT_CLASS)) ?
        (config.buttonVisibility.searchtab) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.SEARCH_BTN_RESULT_CLASS).classList.toggle('hide') : null : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.SHARE_BTN_CLASS)) ?
        (config.buttonVisibility.sharetab) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.SHARE_BTN_CLASS).classList.toggle('hide') : null : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYER_BTN_CLASS)) ?
        (config.buttonVisibility.layertab) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYER_BTN_CLASS).classList.toggle('hide') : null : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS)) ?
        (config.buttonVisibility.fullScreenVisibility) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS).classList.toggle('hide') : null : null;

        (me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_BTN_CLASS)) ?
        (config.buttonVisibility.legendtab) ? me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_BTN_CLASS).classList.toggle('hide') : null : null;

        if (me.variables.backgroundLayerAmount === me.variables.config.layers.length && me.variables.config.layers.length === 1) {
            if (me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYER_BTN_CLASS)) {
                me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYER_BTN_CLASS).classList.add('hide');
            }
        } if (me.variables.backgroundLayerAmount === me.variables.config.layers.length) {
            me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYER_BTN_HEADER) && me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYER_BTN_HEADER).classList.add('hide');
        }

        me.basicMap._bindAppEvents(me);
        me.basicMap._initMap(me);

        (me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_BTN_CLASS).classList.contains('hide'))
            ? (me.variables.legendShowOnStart = false, me.variables.wrapperDiv.querySelector('.s-map-panel').querySelector(me.constants.prototype.LEGEND_CONTAINER_DIV).classList.add('s-map-tab-panel-hidden'))
            : (me.variables.legendShowOnStart = true, me.basicMap.showHideLegend('hide', null, me));

        (me.variables.wrapperDiv.querySelector(me.constants.prototype.TOOLS_BTN_CLASS).classList.contains('hide')) ?
        me.variables.wrapperDiv.querySelector('.s-map-panel').querySelector(me.constants.prototype.TOOLS_CONTAINER_DIV).classList.add('s-map-tab-panel-hidden') : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.SEARCH_BTN_RESULT_CLASS).classList.contains('hide')) ?
        me.variables.wrapperDiv.querySelector('.s-map-panel').querySelector(me.constants.prototype.SEARCH_CONTAINER_DIV).classList.add('s-map-tab-panel-hidden') : null;
        (me.variables.wrapperDiv.querySelector(me.constants.prototype.SHARE_BTN_CLASS).classList.contains('hide')) ?
        me.variables.wrapperDiv.querySelector('.s-map-panel').querySelector(me.constants.prototype.SHARE_CONTAINER_DIV).classList.add('s-map-tab-panel-hidden') : null;

        return me;
    }

/* END: PROTOTYPE OF CONSTANTS IN MAP */

var basicMap = basicMap ||

    /**
     * Constructor
     */
    function () { };

    basicMap.prototype = {

        /* _advancedMapFunctions
        *
        * Functions for popups and clusterlayers
        * and it's styles
        */
        _advancedMapFunctions: function(me) {

    /* START: WHEN CLICKED ON A LINK IN A CLUSTER POPUP */

            me.variables.container.addEventListener('click', function (e) {
                me.advancedMap.popupclick(e, me.variables.overlay, me.variables.view, me.variables.map, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1));
            });

    /* END: WHEN CLICKED ON A LINK IN A CLUSTER POPUP */

    /* START: WHEN CLICKED ON CLOSE BUTTON ON POPUP */

            // function to close popup
            me.variables.closer.onclick = function () {
                me.advancedMap.popupclose(me.variables.overlay, me.variables.closer, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1));
            };

    /* END: WHEN CLICKED ON CLOSE BUTTON ON POPUP */

    /* START: IF CHANGE POSITION OF FEATURES */
        if (me.variables.config.modify) {
            if (me.variables.config.modify.modifyPosition) {
                me.advancedMap.movePositionOfPois();
                me.variables.modify.forEach(function(obj, i) {
                    obj.on('modifyend', function(ev) {
                        var feature = ev.target.dragSegments_[0][0].feature;
                        feature = (feature.getProperties().features) ? feature.getProperties().features[0] : feature;
                        var coordinates = feature.getGeometry().flatCoordinates;

                        if (feature && me.variables.config.clusterOptions.combinedClusterLayer){
                            var oldFeature = me.variables.mapArray[me.variables.combinedClusterPosition].getSource().getSource().getFeatureById(me.variables.combinedClusterPosition + '-' + feature.getId());

                            oldFeature.setGeometry(coordinates ? new ol.geom.Point(coordinates) : null);
                        } if (me.variables.overlay.getPosition()) {
                            me.variables.overlay.setPosition(undefined);
                            var pixel = me.variables.map.getPixelFromCoordinate(coordinates);

                            // TODO: fix so that new popup is shown when changed position
                            //me.advancedMap.funnelMapclick({'pixel': pixel}, me.variables.map, me.variables.overlay, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), false);
                        }

                        me.event.move(feature);
                    })
                })
            }
        }

    /* END: IF CHANGE POSITION OF FEATURES */

        },


        // --------------------


        // Public methods
        // --------------------

        isEmpty: function(obj) {
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        },

    /* START: GET VALUES FROM MAP */

        getZoom: function(me) {
            return me.variables.view.getZoom();
        },

        getView: function(me) {
            return me.variables.view;
        },

        getOverlay: function(me) {
            return me.variables.overlay;
        },

        getMap: function(me) {
            return me.variables.map;
        },

        // update map based on container
        resizeMap: function(me) {
            me.variables.map.updateSize();
        },

    /* END: GET VALUES FROM MAP */

    /* START: ZOOM FUNCTION*/

        // function for zoom interaction on map
        zoom: function(value, me) {
            var zoom = me.basicMap.getZoom(me) - value;
            me.basicMap.getView(me).animate({
                zoom: zoom,
                duration: 500
            }, function() {

                // refreshes cluster popup with pois when zooming
                if (me.variables.overlay.getPosition()) {
                    setTimeout(function () {
                        if (me.variables.map.hasFeatureAtPixel(me.variables.map.getPixelFromCoordinate(me.variables.overlay.getPosition())) === false) {
                            me.variables.overlay.setPosition(undefined);
                        } else {
                            me.advancedMap.refreshPopupOnAction(me, me.variables.overlay, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1));
                        }
                    }, 80);
                }
            });

            // if advanced.js exists, and if clicked on a link in a cluster popup
            var container = me.variables.wrapperDiv.querySelector(me.constants.prototype.POPUP_INNER);
            (container.querySelector('.cluster-link')) ? me.advancedMap.popupclose(me.variables.overlay, me.variables.closer, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1)) : null;
        },

    /* END: ZOOM FUNCTION*/

    /* START: MOVE TO POSITION*/

        moveToPosition: function(me, coords, duration, zoom, callback) {

            me.basicMap.getView(me).animate({
                center: coords,
                duration: duration,
                zoom: zoom
            }, function (bool) {setTimeout(function () {callback(true); }, 50)});
        },

    /* END: MOVE TO POSITION*/

    /* START: TOGGLE FULLSCREEN MAP*/

        // set map in fullscreen mode
        mapFullScreen: function(me) {
            var config = me.variables.config;
            var mapDiv = me.variables.wrapperDiv.querySelector(me.constants.prototype.EVENT_PREFIX);
            var mapContainer = me.variables.wrapperDiv.parentElement;
            var mapFullscreenButtons = me.variables.wrapperDiv.querySelectorAll('button');
            var ua = window.navigator.userAgent;
            var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
            var webkit = !!ua.match(/WebKit/i);
            var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

            if(iOSSafari === true || window.innerWidth > 578) {
                for(var i = 0; i < mapFullscreenButtons.length; i++) {
                    if(mapFullscreenButtons[i].classList.contains('s-map-btn-fullscreen') === true) {
                        if(mapContainer.classList.contains('s-map-e-service') === true || mapContainer.classList.contains('s-map-md') === true || mapContainer.classList.contains('s-map-sm') === true) {
                            document.body.classList.toggle(me.constants.prototype.BODY_FULLSCREEN_MAP_CLASS);
                            mapDiv.classList.toggle(me.constants.prototype.MAP_VIEWPORT_FULLSCREEN);
                        }
                    }
                }
            }

            document.body.classList.toggle(me.constants.prototype.BODY_FULLSCREEN_CLASS);
            if (config.mapOption && config.mapOption.scrollToSearchInput) {
                // Add additional class to body if scroll to search is enabled
                document.body.classList.toggle(me.constants.prototype.BODY_FULLSCREEN_SEARCH_CLASS);
            }

            var buttonWhenFullScreen = me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS).querySelector('.icon');
            var fullscreenBtn = me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS).getAttribute('aria-expanded');
            (fullscreenBtn === 'true') ? me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS).setAttribute('aria-expanded', false) :
                me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS).setAttribute('aria-expanded', true);

            buttonWhenFullScreen.classList.toggle('icon-fullscreen-exit-light');
            buttonWhenFullScreen.classList.toggle('icon-fullscreen-light');

            me.basicMap.mapScrollIntoView(me);

            // if not IE
            mapDiv.addEventListener("transitionend", function() {
                me.basicMap.updateMapSize(me);
            }, false);

            // transitionend not working in ie, so using this workaround, IE 11 & Edge
            if (!(window.ActiveXObject) && "ActiveXObject" in window) {
                me.basicMap.updateMapSize(me);
            }

            // IE 10
            if ((window.ActiveXObject) && "ActiveXObject" in window) {
                me.basicMap.updateMapSize(me);
            }
        },

        mapScrollIntoView: function(me) {
            var config = me.variables.config,
                div = me.variables.wrapperDiv.querySelector(me.constants.prototype.EVENT_PREFIX);

            if (config.mapOption && config.mapOption.scrollToSearchInput) {
                div = document.querySelector(me.constants.prototype.SEARCH_INPUT_CONTAINER);
            }

            div.scrollIntoView();
        },

        updateMapSize: function(me) {
            setTimeout(function () {
                me.basicMap.mapScrollIntoView(me);
                // set position
                me.variables.map.updateSize();
            }, 200);
        },


    /* START: SEND ARRAY TO CREATE LAYER LEGEND */

        // send layers to GetLegend function to create legend tab
        sendToLegend: function(layers, me) {
            // populating legend
            me.structureMap.GetLegend(me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), layers);
        },

    /* END: SEND ARRAY TO CREATE LAYER LEGEND */

    /* END: SORT LAYERS FOR MAP AND LAYER TOGGLE */

    /* START: GEOLOCATION */

        // on - off switch for geolocation
        getPostion: function(obj, me) {

            me.variables.geolocation.setTracking(true);

            obj.classList.add(me.constants.prototype.GEOLOCATOR_BTN_ACTIVE_CLASS);

                if (me.variables.geolocation.getPosition()) {
                    obj.classList.remove(me.constants.prototype.GEOLOCATOR_BTN_ACTIVE_CLASS);
                    var coordinates = me.variables.geolocation.getPosition();
                    me.variables.positionFeature.setGeometry(coordinates ?
                        new ol.geom.Point(coordinates) : null);

                    me.basicMap.moveToPosition(me, coordinates, 500, me.basicMap.getZoom(me), function(complete){});
                }
        },

        // close to position
        getCloseTo: function(obj, me) {

            me.variables.geolocation.setTracking(true);

            obj.classList.add(me.constants.prototype.GEOLOCATION_CLOSE_BTN_ACTIVE_CLASS);

            if (me.variables.geolocation.getPosition()) {
                obj.classList.remove(me.constants.prototype.GEOLOCATION_CLOSE_BTN_ACTIVE_CLASS)
                var coordinates = me.variables.geolocation.getPosition();
                var zoomTo = (me.variables.closeToZoom != null) ? me.variables.closeToZoom : me.basicMap.getZoom();
                me.basicMap.moveToPosition(me, coordinates, 500, zoomTo, function(complete){});
            }
        },

    /* END: GEOLOCATION */

    /* START: TOGGLE MAP TABS */

        // find what to toggle
        mapToggle: function(string, me) {

            // ugly hack way if click on shown div
            var check = me.variables.wrapperDiv.querySelector('.s-map-panel-' + string).classList.contains('show');

            var array = me.variables.wrapperDiv.querySelectorAll('.s-map-tab-panel');
            for (var p in array) {
                var element = array[p];
                if (typeof element === 'object' && element.classList.contains('show')) {
                    var l = element.getAttribute('aria-labelledby');
                    var arrayPosition = l.split('-');
                    me.structureMap.toggleFunction(arrayPosition[arrayPosition.length - 1], me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1));
                }
            }

            // ugly hack way if click on same shown div
            if (!check) {
                me.structureMap.toggleFunction(string, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1));
            }
        },

    /* END: TOGGLE MAP TABS */

    /* START: MAP LAYER VISIBILITY */

        // function for controlling layer visibility
        toggleLayer: function (layer, layerIndex, me) {
            var source = layer.cluster
                    ? layer.getSource().getSource()
                    : layer.getSource(),
                isActive = !source.get('isActive');

            source.set('isActive', isActive);

            me.basicMap.hidePopupIfPixeldonthavefeature(me);
            me.basicMap.showHideLegend(isActive ? 'show': 'hide', layer, me);

            // refreshes cluster popup with pois when toggling poi layers
            if (me.variables.overlay.getPosition()) {
                setTimeout(function () {
                    if (me.variables.map.hasFeatureAtPixel(me.variables.map.getPixelFromCoordinate(me.variables.overlay.getPosition())) === false) {
                        me.variables.overlay.setPosition(undefined);
                    }

                    var overlay = me.variables.overlay;
                    me.advancedMap.refreshPopupOnAction(me, overlay, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1));
                }, 80);
            }
        },

        toggleBackgroundLayer: function (layer, me) {
            if (me.variables.currentBackgroundLayer) {
                me.variables.currentBackgroundLayer.getSource().set('isActive', false);
                me.variables.currentBackgroundLayer.setVisible(false);
                me.basicMap.showHideLegend('hide', me.variables.currentBackgroundLayer, me);
            }

            layer.setVisible(true);
            layer.getSource().set('isActive', true);
            me.variables.currentBackgroundLayer = layer;

            me.basicMap.showHideLegend('show', layer, me);
        },

        hidePopupIfPixeldonthavefeature: function(me) {
            if (me.variables.overlay.getPosition()) {
                setTimeout(function () {
                    if (me.variables.map.hasFeatureAtPixel(me.variables.map.getPixelFromCoordinate(me.variables.overlay.getPosition())) === false) {
                        me.variables.overlay.setPosition(undefined);
                    }
                }, 80);
            }
        },

        showHideLegend: function(string, layer, me) {
            if (layer) {
                var legend = me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_CONTAINER + '-' + me.constants.prototype.EVENT_PREFIX_WRAPPER.substr(1) + '-' + layer.ol_uid);
                if (legend) {
                    string === 'show' ? legend.classList.remove('hide') : legend.classList.add('hide');
                }
            }

            if (me.variables.legendShowOnStart) {
                var legendWrapper = me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_CONTAINER_DIV),
                    activeLegends = legendWrapper.querySelectorAll('.s-map-legend').length > legendWrapper.querySelectorAll('.hide').length;

                if (activeLegends) {
                    me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_BTN_CLASS).classList.remove("hide");
                    me.variables.wrapperDiv.querySelector('.s-map-panel').querySelector(me.constants.prototype.LEGEND_CONTAINER_DIV).classList.remove('s-map-tab-panel-hidden');
                } else {
                    me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_BTN_CLASS).classList.add("hide");
                    me.variables.wrapperDiv.querySelector('.s-map-panel').querySelector(me.constants.prototype.LEGEND_CONTAINER_DIV).classList.add('s-map-tab-panel-hidden');
                }
            }
        },

    /* END: MAP LAYER VISIBILITY */

    /* START: autoCompleteStreetNames */
    autoCompleteStreetNames: function(inputControl, me) {

        const searchText = inputControl.value;

        var beardDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_BEARD_CLASS);
        var resetDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_RESET_CLASS);
        if (searchText==""){

            beardDiv.classList.add('hide');
            resetDiv.classList.add('hide');
            inputControl.setAttribute("aria-expanded", "false");
            return;
        }else{
            resetDiv.classList.remove('hide');
        }

        const streetNamePattern = searchText.trim();
        var url = `https://kartor.stockholm.se/bios/webquery/app/baggis/web/web_query?section=address*suggestfull&resulttype=json&outcoordsys=epsg:3011&1=${streetNamePattern}&maxrows=10`

        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.onerror = function () {  };
        xhr.onload = function () {
            if (xhr.status == 200) {
                var results = JSON.parse(xhr.responseText);
                if (results!=null && results["dbrows"].length>0){
                    var stockholmResult = results;
                    var beardDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_BEARD_CLASS);
                    if (results["dbrows"].length > 0){
                        beardDiv.classList.remove("hide");
                        inputControl.classList.remove("s-map-search-no-hits");
                        inputControl.setAttribute("aria-expanded", "true");
                        var reducedList = [];
                        let counter = 0;
                        for (let i = 0; i < stockholmResult["dbrows"].length; i++) {
                            reducedList.push(stockholmResult["dbrows"][i]["RESULT"]);
                        }
                        me.structureMap.createSearchResultBeard(beardDiv,
                                                                reducedList,
                                                                function(e){
                                                                    e.preventDefault();
                                                                    inputControl.value = e.currentTarget.getAttribute('data-value');
                                                                    inputControl.focus();
                                                                    me.basicMap.searchAddress(inputControl, me);
                                                                    me.basicMap.resetSearchResults(me);
                                                                });
                    } else {
                        me.basicMap.resetSearchResults(me);
                    }
                }else{
                    me.basicMap.resetSearchResults(me);
                }
            } else {

                xhr.onerror();
            }
        };
        xhr.send();
    },
    /* END: autoCompleteStreetNames */

    /* START: SEARCH ADDRESS */
    searchAddress: function(inputControl, me, errorCallback) {
        const searchString = inputControl.value
        if (searchString==""){
            var resetDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_RESET_CLASS);
            resetDiv.classList.add('hide');
            return;
        }

        var regularExpression = new RegExp("^(.*[^-])\\s(\\d.*)$");
        var result = regularExpression.exec(searchString);

        if (result==null){
            errorCallback();
            return;
        }

        if (result.length>2){
            var streetName = result[1];
            var streetNumber = result[2];
        }

        var url = `https://kartor.stockholm.se/bios/webquery/app/baggis/web/web_query?section=geocode*address&resulttype=json&outcoordsys=epsg:3011&1=${streetName}&2=${streetNumber??"*"}`

        var xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.onerror = function () {  };
        xhr.onload = function () {
            if (xhr.status == 200) {
                inputControl.classList.remove("s-map-search-no-hits");
                var results = JSON.parse(xhr.responseText);
                if (results["dbrows"].length == 1){
                    let wkt = new ol.format.WKT();
                    var geometryString = 'POINT (' + results["dbrows"][0]["X"] + ' ' + results["dbrows"][0]["Y"] + ')';
                    var geometry = wkt.readGeometry(geometryString); /* ex: [154031,6578889]; */
                    me.variables.searchPositionFeature.setGeometry(geometry);
                    me.basicMap.moveToPosition(me, geometry.flatCoordinates, 500, me.variables.singleZoom, function(complete){});
                } else {
                    errorCallback();
                }
            } else {
                errorCallback();
                xhr.onerror();
            }
        };
        xhr.send();
    },
    /* END: SEARCH ADDRESS */
    /* START: RESET SEARCH */
     resetSearch: function (me) {
        me.basicMap.resetSearchResults(me);
        var input = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_TEXT_INPUT_ID);
        input.value = "";
        if (me.variables.searchPositionFeature!=null){
            me.variables.searchPositionFeature.setGeometry(null);
        }
     },
     resetSearchResults(me){
        var beardDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_BEARD_CLASS);
        var input = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_TEXT_INPUT_ID);
        beardDiv.classList.add('hide');
        input.setAttribute("aria-expanded", "false");
        input.classList.remove("s-map-search-no-hits");
     }
    /* END: RESET SEARCH */
};

/* END: MAP LAYER FACTORY */


if(typeof(imageStruct) == "function" && typeof(mapStruct) == "function" && typeof(createPopup) == "function" && typeof(basicMap) == "function"){
    if (!scope.init) {
        scope.init =  _init;
    }
}

})(self.smap = self.smap || {});
/* END: CREATING PROTOTYPES OF STRUCTS */
