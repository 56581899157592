function createClusterLayer(smap) {

    function extend(base, prototypes) {
        var ctor = prototypes.constructor;
        ctor.prototype = Object.create((base && base.prototype) || {});

        for (var property in prototypes) {
            if (prototypes.hasOwnProperty(property)) {
                ctor.prototype[property] = prototypes[property];
            }
        }

        return ctor;
    }

    var Source = ol.source.Source,
        Collection = ol.Collection;

    var CombinedSource = extend(Source, {

        _sourceKeys: null,

        constructor: function (options) {
            Source.call(this, options);

            this._sourceKeys = {};
            this._overlaps = options.overlaps === undefined ? true : options.overlaps;

            this._bindSourcesCollection(new Collection(options.sources || []));
        },

        loadFeatures: function (extent, resolution, projection) {
            this._sources.forEach(function (source) {
                source.loadFeatures(extent, resolution, projection);
            });
        },

        getFeatures: function() {
            var features = [];
            this._sources.forEach(function (source) {
                if (source.get('isActive') && source.get('enabled')) {
                    features = features.concat(source.getFeatures());
                }
            });

            return features;
        },

        getFeaturesInExtent: function (extent) {
            var features = [];
            this._sources.forEach(function (source) {
                if (source.get('isActive')  && source.get('enabled')) {
                    features = features.concat(source.getFeaturesInExtent(extent));
                }
            });

            return features;
        },

        getSources: function () {
            return this._sources;
        },

        _bindSourcesCollection: function (collection) {
            collection.on('add', function (evt) { this._addSource((evt.element)); });
            collection.on('remove', function (evt) { this._removeSource((evt.element)); });

            collection.forEach(function(source) { this._addSource(source, true); }.bind(this));

            this._sources = collection;
        },

        _addSource: function (source, isInternal) {
            source.on(['change', 'change:isActive', 'change:enabled'], this.refresh.bind(this));

            !isInternal && this.refresh();
        },

        _removeSource: function (source) {
            source.un('change', this.refresh);
            source.un('change:isActive', this.refresh);

            this.refresh();
        },

        refresh: function () {
            this.changed();
        }
    });

    return {
        /* START: CREATE COMBINED CLUSTER LAYER FOR ALL VECTOR LAYERS WITH POPUPS  */

        createCombinedClusterLayer: function(layers, sources, me) {
            var clusterSource = new ol.source.Cluster({
                distance: parseInt(me.variables.config.clusterOptions.clusterRadius || 70),
                source:  new CombinedSource({ sources: sources })
            });

            clusterSource.set('name', 'Intressepunkter');

            // vectorlayer for map with features of POI:s
            var combinedClusterLayer = new ol.layer.Vector({
                    source: clusterSource,
                    visible: true,
                    style: me.advancedMap.styleFunction,
                    zIndex: 999
            });

            combinedClusterLayer['placement'] = { backgroundlayer: false, backgroundlayername: '', order: 99 };
            combinedClusterLayer['initialVisibility'] = true;
            combinedClusterLayer['isCombinedClusterLayer'] = true;
            combinedClusterLayer['cluster'] = true;

            return combinedClusterLayer;
        }
        /* END: CREATE COMBINED CLUSTER LAYER FOR ALL VECTOR LAYERS WITH POPUPS  */
    };
}