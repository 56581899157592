function createMapEvents(smap) {
    var currentZoomLevel = -1;

    createMapEvents.prototype = {

    /*	_bindAppEvents
    *
    */
        _bindAppEvents: function(me) {

        // ---- Events START --------

        /* START: IF WINDOW IS RESIZED FUNCTION */

            // if window is resized
            window.addEventListener("resize", function () {
                me.basicMap.resizeMap(me);
            });

        /* END: IF WINDOW IS RESIZED FUNCTION */

        // ---- Events END --------
        },

        _createMapControls: function(me) {

            /* START: CLICK EVENTS */
            function addEvent(el, type, handler) {
                me.structureMap.addEvent(el, type, function (e) {
                    if ((window.ActiveXObject) && "ActiveXObject" in window) {
                    } else {
                        e.stopPropagation();
                        e.preventDefault();
                    }
                    handler();
                });
            }


            // click event function zoom out
            var buttonOut = me.variables.wrapperDiv.querySelector(me.constants.prototype.ZOOM_OUT_BTN_CLASS);
            (buttonOut) ? addEvent(buttonOut, 'click', function () { me.basicMap.zoom(1, me); }) : null;

            // click event function zoom in
            var buttonIn = me.variables.wrapperDiv.querySelector(me.constants.prototype.ZOOM_IN_BTN_CLASS);
            (buttonIn) ? addEvent(buttonIn, 'click', function () { me.basicMap.zoom(-1, me); }) : null;

            // click event function expand map to fullsize
            var buttonFullScreen = me.variables.wrapperDiv.querySelector(me.constants.prototype.FULLSCREEN_BTN_CLASS);
            (buttonFullScreen) ? addEvent(buttonFullScreen, 'click', function () { me.basicMap.mapFullScreen(me); }) : null;

            // click event for legend buttons
            var buttonMapLegend = me.variables.wrapperDiv.querySelector(me.constants.prototype.LEGEND_BTN_CLASS);
            (buttonMapLegend) ? addEvent(buttonMapLegend, 'click', function () { me.basicMap.mapToggle('legend', me); }) : null;

            // click event for layers buttons
            var buttonMapLayers = me.variables.wrapperDiv.querySelector(me.constants.prototype.LAYERS_BTN_CLASS);
            (buttonMapLayers) ? addEvent(buttonMapLayers, 'click', function () { me.basicMap.mapToggle('layers', me); }) : null;

            // click event for tools buttons
            var buttonMapTools = me.variables.wrapperDiv.querySelector(me.constants.prototype.TOOLS_BTN_CLASS);
            (buttonMapTools) ? addEvent(buttonMapTools, 'click', function () { me.basicMap.mapToggle('tools', me); }) : null;

            // click event for searchresult buttons
            var buttonMapSearch = me.variables.wrapperDiv.querySelector(me.constants.prototype.SEARCH_BTN_RESULT_CLASS);
            (buttonMapSearch) ? addEvent(buttonMapSearch, 'click', function () { me.basicMap.mapToggle('searchresult', me); }) : null;

            // click event for share buttons
            var buttonMapShare = me.variables.wrapperDiv.querySelector(me.constants.prototype.SHARE_BTN_CLASS);
            (buttonMapShare) ? addEvent(buttonMapShare, 'click', function () { me.basicMap.mapToggle('share', me); }) : null;

            // click event for geolocation
            var buttonGetPosition = me.variables.wrapperDiv.querySelector(me.constants.prototype.GEOLOCATION_BTN_CLASS);
            (buttonGetPosition) ? addEvent(buttonGetPosition, 'click', function () { me.basicMap.getPostion(buttonGetPosition, me); }) : null;

            // click event for address search
            var searchButton = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_BTN_ID);
            (searchButton) ? searchButton.addEventListener('click', function (e) {
                e.preventDefault();
                var searchInputElement = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_TEXT_INPUT_ID);
                me.basicMap.searchAddress(searchInputElement, me, function(){
                    searchInputElement.classList.toggle("s-map-search-no-hits");
                    var beardDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_BEARD_CLASS);
                    me.structureMap.createNoResultBeard(beardDiv, me);
                    searchInputElement.setAttribute("aria-expanded", "true");
                    beardDiv.classList.remove('hide');
                });
            }) : null;


            // Input field event for address search
            var searchInput = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_TEXT_INPUT_ID);
            (searchInput) ? searchInput.addEventListener("input", function(e) {
                me.basicMap.autoCompleteStreetNames(this, me);}) : null;

            (searchInput) ? searchInput.addEventListener("keydown", function(e) {
                switch(e.key) {
                    case "Enter":
                        // Cancel the default action, if needed
                        e.preventDefault();
                        // Trigger the button element with a click
                        if (searchButton != null){
                            searchButton.click();
                        }
                        break;
                    case "ArrowDown":
                        e.preventDefault();
                        var result = me.variables.wrapperDiv.parentNode.querySelector('.arrow-down-to');
                        result.classList.add('s-map-search-list-selected');
                        result!= null ? result.focus() : null;
                    default:
                  }
                }) : null;

            // Reset input
            var inputReset = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_RESET_CLASS);
            (inputReset) ? inputReset.addEventListener("click", function(e) {
                var input = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_TEXT_INPUT_ID);
                var resetDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_RESET_CLASS);
                resetDiv.classList.add('hide');
                input.value = "";
                me.basicMap.resetSearch(me)
                ;}) : null;

            // Update map size
            var mainMapDiv = document.querySelector(me.constants.prototype.EVENT_PREFIX_WRAPPER);
            var mapDiv = mainMapDiv.querySelector(me.constants.prototype.EVENT_PREFIX);
            mapDiv.addEventListener("transitionend", function(/*event*/) {
                me.variables.map.updateSize();
            }, false);

        /* END: CLICK EVENTS */

        /* OTHER EVENTS */
        // Show 1 km instead of 1000 m
            var scaleLineDiv = document.getElementsByClassName("ol-scale-line-inner")[0];
            scaleLineDiv.addEventListener ('DOMSubtreeModified', function(e){
                if (e.currentTarget.innerHTML.toLowerCase() == "1000 m"){
                document.getElementsByClassName("ol-scale-line-inner")[0].innerHTML="1 km";
                }
            }, false);

        /* OTHER EVENTS */

        },

    /*  _initMapControls
    *
    */
        _initMapControls: function(me) {

            /* START: WHEN GEOLOCATION RECIVES A NEW LOCATION */

            // if geolocation changes position this activates
            me.variables.geolocation.on('change:position', function () {
                var coordinates = me.variables.geolocation.getPosition();

            // check if close by button have been activated
                me.variables.positionFeature.setGeometry(coordinates ? new ol.geom.Point(coordinates) : null);

                if (me.variables.wrapperDiv.querySelector(me.constants.prototype.GEOLOCATION_BTN_CLASS).classList.contains(me.constants.prototype.GEOLOCATOR_BTN_ACTIVE_CLASS)) {
                    me.variables.wrapperDiv.querySelector(me.constants.prototype.GEOLOCATION_BTN_CLASS).classList.remove(me.constants.prototype.GEOLOCATOR_BTN_ACTIVE_CLASS);
                    me.basicMap.moveToPosition(me, coordinates, 1500, me.basicMap.getZoom(me), function (/*complete*/) {});
                }
            });

            /* END: WHEN GEOLOCATION RECIVES A NEW LOCATION */

            /* START: MAP EVENT WHEN HOVER OVER A FEATURE */

            // if is over a feature
            me.variables.map.on("pointermove", function (evt) {
                // if feature on this pixel
                var hit = this.forEachLayerAtPixel(evt.pixel, function (layer) {
                    var cursor;

                    if (layer && !(layer.placement && layer.placement.backgroundlayer)) {
                        if ((!!layer.getSource().params_ && !!layer.getSource().getProperties().template) || ((typeof layer.getSource().getFeatures === 'function')) || me.variables.mapHoover === true) {
                            cursor = 'pointer';
                        }
                    } else if (me.basicMap.isEmpty(me.variables.tools) && this.hasFeatureAtPixel(evt.pixel) ) {
                        cursor = 'move';
                    }

                    return cursor;
                }.bind(this));

                document.body.style.cursor = hit || '';
            });

            /* END: MAP EVENT WHEN HOVER OVER A FEATURE */

            /* START: MAP EVENT WHEN CLICK ON MAP */

            // event when clicked on map
            me.variables.map.on('singleclick', function (evt) {
                me.advancedMap.mapclick(evt, me.variables.map, me.variables.overlay, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), me);
            });

            /* END: MAP EVENT WHEN CLICK ON MAP */
            me.variables.map.on('moveend', function () {
                var zoomLevel = me.basicMap.getZoom(me);

                if (zoomLevel !== currentZoomLevel) {
                    smap.variables.map.getLayers().forEach(function (layer) {
                        layer.checkZoomConstraints && layer.checkZoomConstraints(zoomLevel);
                    });

                    if (me.variables.combinedClusterLayer) {
                        // Check if the zoom level requires switching to/from the combined cluster layer
                        me.variables.combinedClusterLayer.setVisible(zoomLevel <= me.variables.changeToCombinedLayerLevel);
                    }
                }

                if (me.variables.combinedClusterLayer) {
                    me.variables.combinedClusterLayer.on('postrend', function (/*evt*/) {

                        if (me.variables.overlay.getPosition()) {
                            if (me.variables.map.hasFeatureAtPixel(me.variables.map.getPixelFromCoordinate(me.variables.overlay.getPosition())) && me.variables.combinedClusterLayer.getVisible() !== false) {
                                me.advancedMap.funnelMapclick({'pixel': me.variables.map.getPixelFromCoordinate(me.variables.overlay.getPosition())}, me.variables.map, me.variables.overlay, me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), false)
                            } else if (me.variables.map.hasFeatureAtPixel(me.variables.map.getPixelFromCoordinate(me.variables.overlay.getPosition())) === false && me.basicMap.getZoom(me) <= me.variables.changeToCombinedLayerLevel) {
                                me.variables.overlay.setPosition(undefined);
                            }
                        }
                    });
                }
            });
        }
    };

    return createMapEvents.prototype;
}
