function createPopup(smap) {

    createPopup.prototype = {

        styles: {
            '1': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoi
                })
            }),
            '2': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiCity
                })
            }),
            '3': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiKungsholmen
                })
            }),
            '4': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiLiljeholmen
                })
            }),
            '5': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiNorrmalm
                })
            }),
            '6': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiOstermalm
                })
            }),
            '7': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiSodermalm
                })
            }),
            '8': new ol.style.Style({
                image: new ol.style.Icon({
                    imgSize: [27, 40],
                    anchor: [0.5, 1],
                    src: smap.imageMap.IconMapPoiVasastan
                })
            })
        },


        // set cluster style of feature depending on length
        styleFunction: function(feature) {
            var style,
                size = (feature.get('features')) ? feature.get('features').length : 1 ;
                size = size.toString();

            if (size > 1) {

                // checks if clusterCount is activated
                if(smap.variables.clusterCount === true) {

                    // first check is for pois total count between 1,000 and 10,000
                    // second is for pois total count between 10,000 and 100,000
                    // third is for poist total count between 100,000 and 1,000,000
                    // fourth is for a million and above
                    if(feature.get('features').length > 999 && feature.get('features').length < 9999) {
                        var sizeFirstChar = size.substring(0,1);
                        var sizeSecondChar = size.substring(1,2)

                        // checks if there is a second character, example: 1,100 becomes 1,1k
                        // else if it is 1,050 it shows first character only: 1k
                        if(sizeSecondChar > 0) {
                            size = sizeFirstChar + ',' + sizeSecondChar + 'k';
                        } else {
                            size = sizeFirstChar + 'k';
                        }
                    } else if(feature.get('features').length > 9999 && feature.get('features').length < 99999) {
                        var sizeFirstChar = size.substring(0,1);
                        var sizeSecondChar = size.substring(1,2);
                        var sizeThirdChar = size.substring(2,3);

                        // works just like the one before but here it also checks for a third character
                        // an example could be 10,100 = 10,1k or 11,100 = 11,1k
                        if(sizeSecondChar < 1 && sizeThirdChar < 1) {
                            size = sizeFirstChar + sizeSecondChar + 'k';
                        } else if(sizeSecondChar > 0 && sizeThirdChar > 0) {
                            size = sizeFirstChar + sizeSecondChar + ',' + sizeThirdChar + 'k';
                        } else if(sizeSecondChar > 0 && sizeThirdChar < 1) {
                            size = sizeFirstChar + sizeSecondChar + 'k';
                        } else if(sizeSecondChar < 1 && sizeThirdChar > 0) {
                            size = sizeFirstChar + sizeSecondChar + ',' + sizeThirdChar + 'k';
                        }
                    } else if(feature.get('features').length > 99999 && feature.get('features').length < 999999) {
                        var sizeFirstChar = size.substring(0,1);
                        var sizeSecondChar = size.substring(1,2);
                        var sizeThirdChar = size.substring(2,3);

                        // 100,000 = 100k, 101,000 = 101k. Does not show hundreds! 101,100 = 101k
                        size = sizeFirstChar + sizeSecondChar + sizeThirdChar + 'k';
                    } else if(feature.get('features').length > 999999) {
                        var sizeFirstChar = size.substring(0,1);
                        var sizeSecondChar = size.substring(1,2)

                        // 1m, 1,1m
                        if(sizeSecondChar > 0) {
                            size = sizeFirstChar + ',' + sizeSecondChar + 'm';
                        } else {
                            size = sizeFirstChar + 'm';
                        }
                    }
                }

                style = new ol.style.Style({
                    image: new ol.style.Icon({
                        imgSize: [48, 70],
                        src: smap.imageMap.IconMapPoiCluster
                    }),
                    text: new ol.style.Text({
                        text: size,
                        font: '16px/1 "Stockholm Type", "Arial", "Helvetica", sans-serif',
                        scale: 1,
                        offsetY: 12,
                        fill: new ol.style.Fill({
                            color: '#fff'
                        })
                    })
                });
            } else {
                var features = feature.get('features'),
                    styleId;

                styleId = features && features.length > 0
                    ? features[0].get('poiType') || features[0].source.get('poiType')
                    :  feature.get('poiType') || feature.source.get('poiType');

                style = createPopup.prototype.styles[styleId];

            }
            return style;
        },

        /* START: CREATE OVERLAY FOR POPUP */

        createOverlay: function(wrapperDiv) {

            var container = wrapperDiv.querySelector(smap.constants.prototype.POPUP_CLASS);
            // overlay layer
            var overlay = new ol.Overlay(({
                element: container,
                offset: [0,-35],
                autoPan: true,
                autoPanMargin: (window.innerWidth < 480) ? (window.innerHeight > 800) ? 120 : 80 : 24,
                className: 's-map-popup',
                positioning: 'bottom-center',
                autoPanAnimation: {
                    duration: 100
                }
            }));

            return overlay
        },

        // when a feature is clicked in the map.
        // sort if cluster or not.
        activatePopup: function(feature, map, overlay, target, positionFunction) {
            var features = feature.get('features');
            // function that control the html struct

            // remove content in popup
            smap.structureMap.reSetPopoverContent(target, smap);

            // if feature is a cluster feature
            if (features) {
                if (features.length > 1) {
                    smap.structureMap.multiPopup(feature, target);
                } else {
                    (smap.variables.config.modify.modifyData)
                        ? smap.structureMap.addOrModifyPopup(features[0], target, smap)
                        : smap.structureMap.singlePopup(features[0], target);
                }
            } else if (feature.getId()) {
                (smap.variables.config.modify.modifyData)
                    ? (features)
                        ? smap.structureMap.addOrModifyPopup(features[0], target, smap)
                        : smap.structureMap.addOrModifyPopup(feature, target, smap)
                    : (features)
                        ? smap.structureMap.singlePopup(features[0], target)
                        : smap.structureMap.singlePopup(feature, target);
            }

            positionFunction && positionFunction();
        },

        // activate fullscreen if window is below 480 px
        windowWidth: function(target) {
            if (window.innerWidth < 480) {
                var wrapperDiv = document.querySelector('.' + target);
                var fullscreenBtnVal = wrapperDiv.querySelector(smap.constants.prototype.FULLSCREEN_BTN_CLASS).getAttribute('aria-expanded');
                if (fullscreenBtnVal === 'false') {
                    smap.basicMap.mapFullScreen(smap);
                }
            }
        },

        // function for when you click on a link in a cluster popup.
        popupclick: function(e, overlay, view, map, target) {

            // get src element or e.target
            var targetObj = e.target ? e.target : e.srcElement;
            var feature = targetObj.featureData;
            var layer;

            // if target are a cluster-link
            if (targetObj.classList.contains('cluster-link')) {
                // remove current content in popup
                smap.structureMap.reSetPopoverContent(target, smap);
                // show new feature in popup
                (smap.variables.config.modify.modifyData) ? smap.structureMap.addOrModifyPopup(feature, target, smap) : smap.structureMap.singlePopup(feature, target);

                // set position
                function setposition(complete){
                    if (complete) {
                        setTimeout(function() {
                            overlay.setPosition(feature.getGeometry().getCoordinates());
                        }, 20);
                    }
                }
                // move to position
                var zoomTo = (smap.variables.clusterZoom != null) ? smap.variables.clusterZoom : smap.basicMap.getZoom(smap);
                smap.basicMap.moveToPosition(smap, feature.getGeometry().getCoordinates(), 150, zoomTo, setposition);

            } else if (targetObj.classList.contains('s-map-popup-delete')) {
                createPopup.prototype.delete(feature);
                overlay.setPosition(null);
            } else if (targetObj.classList.contains('s-map-popup-change')) {
                smap.event.change(feature);

            } else if (targetObj.classList.contains('s-map-popup-save')) {
                createPopup.prototype.save(feature, layer, overlay, smap);
            }
        },

        /* END: CREATE OVERLAY FOR POPUP */

        save: function(feature, layer, overlay, smap) {

            if (feature) {
                createPopup.prototype.modify(feature);
            } else {
                var popup = smap.variables.wrapperDiv.querySelector('.s-map-popup');

                        var data = [{
                            "geometry": {
                                "type": "Point",
                                "coordinates": smap.variables.latestDrawFeature.getGeometry().getFlatCoordinates()
                            },
                            "properties": {
                                "type": "Feature",
                                "name": popup.querySelector('.poi-title-form').value,
                                "poiType": popup.querySelector('.poi-type').options[popup.querySelector('.poi-type').selectedIndex].value,
                                "url": (popup.querySelector('.link')) ? popup.querySelector('.link').value : '',
                                "content": popup.querySelector('.preamble-form').value,
                                "image": popup.querySelector('.poi-img-form').value
                            }
                        }];

                        var source = smap.variables.featureArray.filter(function (a) {
                            if (a.get('name') === popup.querySelector('.poi-layer').options[popup.querySelector('.poi-layer').selectedIndex].value) {
                                return a;
                            }
                        });

                        var layer = smap.variables.mapArray.filter(function (a) {
                            if(a.type === "VECTOR") {
                                if (a.getSource().get('name') === source[0].get('name')) {
                                    return a;
                                }
                            }
                        });

                        createPopup.prototype.createPois(layer[0], data);
                        overlay.setPosition(undefined);
            }
        },

        delete: function(feature) {
            feature.source.removeFeature(feature);
        },

        modify: function(feature, layer, smap) {
            var popup = smap.variables.wrapperDiv.querySelector(smap.constants.prototype.POPUP_INNER);

            (popup.querySelector('.title')) ? (feature.set('name', popup.querySelector('.title').value)) : null ;
            (popup.querySelector('.preamble')) ? (feature.set('content', popup.querySelector('.preamble').value)) : null;
            (popup.querySelector('.link')) ? (feature.set('url', popup.querySelector('.link').value)) : null;
            (popup.querySelector('.img')) ? (feature.set('image', popup.querySelector('.img').value)) : null;

            smap.event.modify(feature);
        },

        change: function(feature) {
            smap.event.change(feature);
        },

        // if close button on popup is clicked
        popupclose: function(overlay, closer, target) {

            smap.structureMap.reSetPopoverContent(target, smap);

            overlay.setPosition(undefined);
            closer.blur();
            return false;
        }

    };

    return createPopup.prototype;
}
