function createMap(smap) {

  createMap.prototype = {
    /* initMap */
    _initMap: function(me) {
      me.basicMap._initMapElements(me);

      me.variables.mapArray = me.basicMap.createMapArrayAndSendToLayers(me.basicMap.sortLayers(me.variables.config.layers, me), me);
      me.basicMap.sendToLegend(me.basicMap.sortLayers(me.variables.config.layers, me), me);

      /* START: CREATES THE MAP OBJECT BASED ON THE CONFIG */
      me.variables.map = new ol.Map({
      target: me.targetDiv,
      pixelRatio: 1,
      layers: me.variables.mapArray,
      overlays: [me.variables.overlay],
      view: me.variables.view,
      controls: ol.control.defaults({
        attribution: false,
        zoom: false,
        enableRotation: false
      }).extend([
        new ol.control.ScaleLine({
          target: me.variables.wrapperDiv.querySelector(me.constants.prototype.SCALELINE_CLASS),
          units: 'metric'
        })
      ]),
      interactions: ol.interaction.defaults({
        keyboardPan: true,
        keyboardZoom: true,
        mouseWheelZoom: false,
        doubleClickZoom: (me.variables.config.mapOption) ? me.variables.config.mapOption.doubleClickZoom : true})
      });

      /* END: CREATES THE MAP OBJECT BASED ON THE CONFIG */
      if (me.variables.config.searchOptions != null){
        var smapContainer = me.variables.wrapperDiv.parentNode;
        (me.variables.config.searchOptions.enabled) ?smap.structureMap.createSearchBar(smapContainer) : null;
      }

      me.basicMap._initMapGeolocation(me);
      me.basicMap._initMapSearchResultLocation(me);
      me.basicMap._initMapControls(me);
      me.basicMap._createMapControls(me);
      me.basicMap._advancedMapFunctions(me);

      var toolTab = me.variables.wrapperDiv.querySelector('.s-map-panel-tools').querySelector('.s-map-tab-panel-inner');
      (me.variables.config.tools) ? me.structureMap.addToolButton(me.variables.config.tools.customButton, toolTab, me) : null;

      return true;
    },

    /* initMapElements */
    _initMapElements: function(me) {
      /* START: CREATING SWEREF 99 or EPSG:3011 PROJECTION */
      proj4.defs("EPSG:3011", "+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
      ol.proj.proj4.register(proj4);
      me.variables.proj3011 = ol.proj.get('EPSG:3011');
      me.variables.proj3011.setExtent([-359975.93, 6121896.23, 395723.43, 7677606.20]);
      /* END: CREATING SWEREF 99 or EPSG:3011 PROJECTION */

      /* START: CREATING VIEW OBJECT */
      me.variables.view = new ol.View({
        center: me.variables.config.center,
        zoom: me.variables.config.zoomLevel.zoom,
        enableRotation: false,
        projection: me.variables.proj3011,
        minZoom: me.variables.minZoom,
        maxZoom: me.variables.maxZoom,
        maxResolution: (me.variables.config.mapOption) ? (me.variables.config.mapOption.maxResolution) ? me.variables.config.mapOption.maxResolution : undefined : undefined,
        minResolution: (me.variables.config.mapOption) ? (me.variables.config.mapOption.minResolution) ? me.variables.config.mapOption.minResolution : undefined : undefined,
        extent: (me.variables.config.mapOption) ? (me.variables.config.mapOption.extent) ? me.variables.config.mapOption.extent : undefined : undefined
      });
      /* END: CREATING VIEW OBJECT */
    },

    _initMapGeolocation: function(me) {
      /* START: CREATING GEOLOCATION OBJECT */
      me.variables.geolocation = new ol.Geolocation({
        projection: me.variables.view.getProjection(),
      });
      /* END: CREATING GEOLOCATION OBJECT */

      /* START: CREATING STYLE AND LAYER FOR GEOLOCATION */
      me.variables.positionFeature = new ol.Feature({
        type: 'GeolocationPosition'
      });
      me.variables.positionFeature.setStyle(new ol.style.Style({
        image: new ol.style.Icon({
          imgSize: [54, 54],
          anchor: [0.5, 0.5],
          src: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9InRhcmdldCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjU0cHgiIGhlaWdodD0iNTRweCIgdmlld0JveD0iMCAwIDU0IDU0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NCA1NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtvcGFjaXR5OjAuNDt9Cgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxjaXJjbGUgaWQ9ImNpcmNsZSIgY2xhc3M9InN0MCIgY3g9IjI3IiBjeT0iMjciIHI9IjI3Ii8+CjxjaXJjbGUgaWQ9InBvaW50XzFfIiBjbGFzcz0ic3QxIiBjeD0iMjciIGN5PSIyNyIgcj0iMyIvPgo8cGF0aCBpZD0idGFyZ2V0XzFfIiBjbGFzcz0ic3QxIiBkPSJNMzQuMiwyNS45Yy0wLjUtMy4xLTMtNS42LTYuMS02LjFWMTdoLTIuMnYyLjhjLTMuMSwwLjUtNS42LDMtNi4xLDYuMUgxN3YyLjJoMi44CgljMC41LDMuMSwzLDUuNiw2LjEsNi4xVjM3aDIuMnYtMi44YzMuMS0wLjUsNS42LTMsNi4xLTYuMUgzN3YtMi4ySDM0LjJ6IE0yNywzMi40Yy0zLDAtNS40LTIuNC01LjQtNS40YzAtMywyLjQtNS40LDUuNC01LjQKCWMzLDAsNS40LDIuNCw1LjQsNS40QzMyLjQsMzAsMzAsMzIuNCwyNywzMi40eiIvPgo8L3N2Zz4K'
        })
      }));

      // layer for geolocation
      new ol.layer.Vector({
        map: me.variables.map,
        source: new ol.source.Vector({
          features: [me.variables.positionFeature]
        })
      });
    },

    _initMapSearchResultLocation: function(me) {
      // position point style geolocation
      me.variables.searchPositionFeature = new ol.Feature({
        type: 'SearchLocationPosition'
      });

      me.variables.searchPositionFeature.setStyle(new ol.style.Style({
        image: new ol.style.Icon({
          imgSize: [54, 54],
          anchor: [0.5, 0.5],
          src: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9InRhcmdldCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgd2lkdGg9IjU0cHgiIGhlaWdodD0iNTRweCIgdmlld0JveD0iMCAwIDU0IDU0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NCA1NDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtvcGFjaXR5OjAuNDt9Cgkuc3Qxe2ZpbGw6I0ZGRkZGRjt9Cjwvc3R5bGU+CjxjaXJjbGUgaWQ9ImNpcmNsZSIgY2xhc3M9InN0MCIgY3g9IjI3IiBjeT0iMjciIHI9IjI3Ii8+CjxjaXJjbGUgaWQ9InBvaW50XzFfIiBjbGFzcz0ic3QxIiBjeD0iMjciIGN5PSIyNyIgcj0iMyIvPgo8cGF0aCBpZD0idGFyZ2V0XzFfIiBjbGFzcz0ic3QxIiBkPSJNMzQuMiwyNS45Yy0wLjUtMy4xLTMtNS42LTYuMS02LjFWMTdoLTIuMnYyLjhjLTMuMSwwLjUtNS42LDMtNi4xLDYuMUgxN3YyLjJoMi44CgljMC41LDMuMSwzLDUuNiw2LjEsNi4xVjM3aDIuMnYtMi44YzMuMS0wLjUsNS42LTMsNi4xLTYuMUgzN3YtMi4ySDM0LjJ6IE0yNywzMi40Yy0zLDAtNS40LTIuNC01LjQtNS40YzAtMywyLjQtNS40LDUuNC01LjQKCWMzLDAsNS40LDIuNCw1LjQsNS40QzMyLjQsMzAsMzAsMzIuNCwyNywzMi40eiIvPgo8L3N2Zz4K'
        })
      }));

      // layer for search result location
      new ol.layer.Vector({
        map: me.variables.map,
        source: new ol.source.Vector({
          features: [me.variables.searchPositionFeature]
        })
      });
    }
  };
  return createMap.prototype;
}
