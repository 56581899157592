function mapStruct(smap) {

    mapStruct.prototype = {

    /* START: TAB CONTROLS */

    // toggle tabs
    toggleFunction: function (string, target) {

        var wrapperDiv = document.querySelector('.' + target);

        wrapperDiv.querySelector('.s-map-panel-nav-' + string).classList.toggle('s-map-btn-active');
        wrapperDiv.querySelector('.s-map-panel-nav-' + string).querySelector('.icon').classList.toggle('icon-close');
        wrapperDiv.querySelector('.s-map-panel-' + string).classList.toggle('show');

        var ariaSelected = wrapperDiv.querySelector('.s-map-panel-nav-' + string).getAttribute('aria-expanded');
        (ariaSelected === 'true') ? wrapperDiv.querySelector('.s-map-panel-nav-' + string).setAttribute('aria-expanded', false) :
            wrapperDiv.querySelector('.s-map-panel-nav-' + string).setAttribute('aria-expanded', true);

        var mapPanelNavLayers = document.getElementById('s-map-panel-nav-layers');
        var mapPanelLegend = document.getElementById('s-map-panel-nav-legend');
        var mapPanelNavTools = document.getElementById('s-map-panel-nav-tools');
        var mapPanelNavSearch = document.getElementById('s-map-panel-nav-searchresult');
        var mapPanelNavShare = document.getElementById('s-map-panel-nav-share');

        // changes tabIndex of nav buttons so navigation by keyboard becomes possible without using mouse
        if(mapPanelNavLayers.getAttribute('aria-expanded') === "true") {
            mapPanelLegend.tabIndex = -1;
            mapPanelNavTools.tabIndex = -1;
            mapPanelNavSearch.tabIndex = -1;
            mapPanelNavShare.tabIndex = -1;
        } else if(mapPanelNavTools.getAttribute('aria-expanded') === "true") {
            mapPanelNavShare.tabIndex = -1;
            mapPanelNavSearch.tabIndex = -1;
        } else if(mapPanelNavShare.getAttribute('aria-expanded') === "true") {
            mapPanelNavSearch.tabIndex = -1;
        } else {
            mapPanelLegend.tabIndex = 0;
            mapPanelNavLayers.tabIndex = 0;
            mapPanelNavTools.tabIndex = 0;
            mapPanelNavSearch.tabIndex = 0;
            mapPanelNavShare.tabIndex = 0;
        }
    },

    /* END: TAB CONTROLS */

    /* START: LEGEND DATA */

    GetLegend: function (id, object) {
        object.reverse();
        object.forEach(function (item, i) {
            var index = object.length - (i + 1);

            if(item.legend) {
                var obj = item.legend;
                // creating div
                var wrapper = document.createElement('div');
                // giving it a class
                wrapper.classList.add("s-map-legend");
                // giving it a special class if its a geojson layer
                if (item.type === 'geoJSON') {
                    wrapper.classList.add("poi");
                }
                // giving it a id, so to be able to showhide it, based on map toggle.
                wrapper.setAttribute('id', 's-map-legend-' + id + '-' + item.layerId);
                if (item.showhide === false) {
                    wrapper.classList.add("hide");
                }

                // creating legend header, polulating it and append header to legend.
                if (obj.header){
                    var mainDivHeader = document.createElement('span');
                    mainDivHeader.innerHTML = obj.header;
                    mainDivHeader.classList.add('s-map-panel-title');

                    wrapper.appendChild(mainDivHeader);
                }

                // creating p, populating it and append subheader to legend
                if (obj.subheader) {
                    var mainDivSubHeader = document.createElement('p');
                    mainDivSubHeader.innerHTML = obj.subheader;

                    wrapper.appendChild(mainDivSubHeader);
                }

                // creating dl
                var mainDivContent = document.createElement('dl');

                if (obj.content) {
                    obj.content.forEach(function (contentObj) {

                        // creating dt
                        if (contentObj.header) {
                            var mainDivContentHeader = document.createElement('dt');
                            mainDivContentHeader.innerHTML = contentObj.header;

                            // push in dl.
                            mainDivContent.appendChild(mainDivContentHeader);
                        }

                        // creating dd
                        var mainDivContentText = document.createElement('dd');

                        // creating figure and img
                        if (contentObj.image) {
                            var mainDivContentFigure = document.createElement('figure');
                            var mainDivContentImage = document.createElement('img');
                            mainDivContentImage.src = contentObj.image;
                            mainDivContentImage.setAttribute('alt','');

                            mainDivContentFigure.appendChild(mainDivContentImage);
                            mainDivContentText.appendChild(mainDivContentFigure);
                        }

                        // creating div populating it and pushing it into dd
                        if (contentObj.text) {
                            var mainDivContentDiv = document.createElement('div');
                            mainDivContentDiv.innerHTML = contentObj.text;
                            // push dd
                            mainDivContentText.appendChild(mainDivContentDiv);
                        }

                        // push in dt and dd to dl.
                        if (contentObj.image || contentObj.text) {
                            mainDivContent.appendChild(mainDivContentText);
                        }
                    });
                }
                // push dl into main divvmainDivContent
                if (mainDivContent.firstChild) {
                     wrapper.appendChild(mainDivContent);
                }

                if (obj !== 'undefined') {

                    // get of unique wrapper div and div where legend struckt will live in
                    var blockWrapper = document.querySelector('.' + id);
                    var legendWrapper = blockWrapper.querySelector('#s-map-panel-legend');

                    if (legendWrapper && wrapper.childNodes.length != 0) {

                        var legendContent = legendWrapper.querySelector('.s-map-tab-panel-inner');
                        legendContent.appendChild(wrapper);

                    }

                }
            }
        });
    },

    /* END: LEGEND DATA */

    /* START: LAYER CONTROL AND CREATE */

    // filter array of layers based on true/false background layer
    GetLayerControl: function (me, allLayers) {
        var id = me.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1);
        var backgroundLayers = allLayers.filter(function (x) {
            return x.placement.backgroundlayer;
        });
        var normalLayers = allLayers.filter(function (x) {
            return !x.placement.backgroundlayer;
        }).reverse();
        var sortedBackground = smap.basicMap.sortBackgroundLayers(backgroundLayers).reverse();

        if (normalLayers.length > 0) {
            mapStruct.prototype.CreateLayerControl(id, normalLayers, allLayers, me);
        }

        if (backgroundLayers.length > 0) {
            sortedBackground.forEach(function (layers) {
                layers.reverse();
                if (layers.length > 1) {
                    mapStruct.prototype.CreateLayerControl(id, layers, allLayers, me);
                }
            });
        }
    },

    // create buttons for layer visibility control
    CreateLayerControl: function (id, currentLayers, allLayers, me) {
        function addEvent(el, type, handler) {
            me.structureMap.addEvent(el, type, function (e) {
                e.stopPropagation();
                e.preventDefault();

                handler();
            });
        }

        var blockWrapper = document.querySelector('.' + id);
        var controlWrapper = blockWrapper.querySelector('.s-map-layers');
        var i = (allLayers.length - 1) - smap.variables.wrapperDiv.querySelectorAll(smap.constants.prototype.LAYER_TOGGLE_BTN_CLASS).length;

        (smap.variables.config.clusterOptions.combinedClusterLayer) ? i = i - 1: null;

        if (currentLayers[0].placement.backgroundlayer === true) {
            var header = document.createElement('span');
            header.innerHTML = currentLayers[0].placement.backgroundlayername;
            header.classList.add('s-map-panel-header');
            controlWrapper.appendChild(header);
        }

        currentLayers.forEach(function (layer, y) {
            if (layer.isCombinedClusterLayer) {
                return;
            }

            var objectID = 's-map-switch-layer-' + id + '-' + i;
            var layerName = layer.getSource().get('name');
            var icon = layer.getSource().get('icon');
            var ariaLabel = 's-map-switch-layer-label-' + id + '-' + i;
            var source = layer.cluster ? layer.getSource().getSource() : layer.getSource();

            // creating wrapper
            var wrapper = document.createElement('button');
            // giving it a class
            wrapper.classList.add('s-switch');
            wrapper.classList.add('s-switch-map-layers');
            wrapper.setAttribute('type', 'button');
            wrapper.setAttribute('role', 'checkbox');
            wrapper.setAttribute('aria-checked', 'false');
            wrapper.setAttribute('id', 'parent-' + objectID);
            (icon) ? wrapper.classList.add('s-switch-poi') : null;

            // creating label
            var label = document.createElement('label');
            label.setAttribute('for', objectID);
            label.setAttribute('class', 's-map-switch-layer');

            // creating span
            var span = document.createElement('span');
            (!icon || parseInt(icon) === 0) ? span.innerHTML = layerName: null;
            span.setAttribute('id', ariaLabel);

            if (icon && parseInt(icon) != 0) {
                var spanWrapper = document.createElement('span');

                var figure = document.createElement('figure');
                var image = document.createElement('img');
                image.alt = '';

                (icon.length > 1) ? image.src = icon : image.src = smap.imageMap[Object.keys(smap.imageMap)[parseInt(icon)]];
                // append in img in figure
                figure.appendChild(image);
                // append in figure in label
                spanWrapper.appendChild(figure);

                var spanText = document.createElement('span');
                spanText.innerHTML = layerName;

                // append in span title in span wrapper
                spanWrapper.appendChild(spanText);

                // append in span wrapper in span
                span.appendChild(spanWrapper);
            }

            // append in span in label
            label.appendChild(span);

            // create input
            var input = document.createElement('input');
            input.setAttribute('type', 'checkbox');
            input.setAttribute('id', objectID);
            input.setAttribute('name', 's-map-layers');
            input.setAttribute('value', i);
            input.setAttribute('aria-labelledby', ariaLabel);
            input.setAttribute('background-layer', layer.placement.backgroundlayer);
            input.checked = layer.type === 'VECTOR' && smap.variables.config.clusterOptions.combinedClusterLayer
                    ? layer.initialVisibility
                    : layer.getVisible();

            // append in input in label
            label.appendChild(input);

            // create i
            icon = document.createElement('i');
            icon.setAttribute('aria-hidden', true);

            // append in icon in label
            label.appendChild(icon);

            // append in label in div
            wrapper.appendChild(label);

            // append all to container
            controlWrapper.appendChild(wrapper);

            // Follow the setting on the source, this makes the auto off toggle of background layers work
            source.on('change:isActive', function (e) {
                var isActive = source.get('isActive');
                wrapper.setAttribute('aria-checked', isActive);
                input.checked = isActive;
            });

            source.on('change:enabled', function (e) {
                var disabled =!source.get('enabled');
                input.disabled = disabled;
                disabled
                    ? wrapper.classList.add('s-disabled')
                    : wrapper.classList.remove('s-disabled');
            });

            addEvent(wrapper, 'click', function () {
                if (!source.get('enabled')) {
                    return;
                }

                if (layer.placement.backgroundlayer) {
                    me.basicMap.toggleBackgroundLayer(layer, me);
                } else {
                    me.basicMap.toggleLayer(layer, i, me);
                }
            });

            i = i - 1;
        });
    },

    /* END: LAYER CONTROL AND CREATE */

    /* START: CREATE MULTI AND SINGEL POPUP */

    // sort multiPopup by layer order
    multiPopupSortLayer: function (arr) {
        arr.sort(function (a, b) {
            var x = a.source.ol_uid || 0;
            var y = b.source.ol_uid || 0;

            return x - y;
        });

        arr.reverse();
        return arr;
    },

    // sort multiPopup by name, returns array with grouped features for each type
    multiPopupSortName: function (features) {
        return features.sort(function (a, b) {
            var x = a.source.ol_uid || 0;
            var y = b.source.ol_uid || 0;
            var result = x - y;

            if (result != 0) {
                return result;
            }

            x = (a.get('name') || '').toLowerCase();
            y = (b.get('name') || '').toLowerCase();

            return x.localeCompare(y);
        });
    },

    // // if feature is cluster iterate each feature and append them as <a>
    multiPopup: function (feature, target) {

        (smap.variables.config.modify.modifyData) ? smap.variables.container.classList = "popup" : null;

        var wrapperDiv = document.querySelector('.' + target);
        var container = wrapperDiv.querySelector(smap.constants.prototype.POPUP_INNER);
        var popupContentWrapper = wrapperDiv.querySelector('.s-map-popup-inner');
        var popupLayerHeader = container.querySelector('.s-map-popup-header');
        var sortedArray = feature.get('features') || [];

        popupLayerHeader.innerHTML = smap.variables.combinedClusterLayer
            ? smap.variables.combinedClusterLayer.getSource().get('name')
            : sortedArray.length > 0 ? sortedArray[0].source.get('name') : '';

        // wrapper nav
        var elementNav = document.createElement('nav');
        elementNav.setAttribute('role', 'navigation');

        if (smap.variables.clusterSortPopupResultByLayer) {
            sortedArray = mapStruct.prototype.multiPopupSortLayer(sortedArray);
        }

        if (smap.variables.clusterSortPopupResultByName) {
            sortedArray = mapStruct.prototype.multiPopupSortName(sortedArray);
        }

        sortedArray.forEach(function (feature) {
            var icon = feature.get('poiType');

            var elementLink = document.createElement('a');
            elementLink.setAttribute('class', 'cluster-link');
            elementLink.setAttribute('feature-id', feature.getId());
            elementLink.setAttribute('feature-coords', feature.getGeometry().flatCoordinates);
            elementLink.href = 'javascript:;';
            elementLink.featureData = feature;

            if (icon && smap.variables.config.clusterOptions.clusterIconPopupShow) {
                if (parseInt(icon) != 0) {
                var spanWrapper = document.createElement('span');

                var figure = document.createElement('figure');
                var image = document.createElement('img');

                (icon.length > 1) ? image.src = icon : image.src = smap.imageMap[Object.keys(smap.imageMap)[parseInt(icon)]];
                // append in img in figure
                figure.appendChild(image);
                // append in figure in label
                spanWrapper.appendChild(figure);

                var spanText = document.createElement('span');
                spanText.innerHTML = feature.get('name');

                // append in span title in span wrapper
                spanWrapper.appendChild(spanText);

                // append in span wrapper in span
                elementLink.appendChild(spanWrapper);
                }
            } else {
                elementLink.innerHTML = feature.get('name');
            }

            elementNav.appendChild(elementLink);

        });

        // append to popup
        popupContentWrapper.appendChild(elementNav);
},

    singlePopupByFullContent: function (header, fullContent, target) {
        smap.variables.container.classList = "popup";

        var wrapperDiv = document.querySelector('.' + target);
        var container = wrapperDiv.querySelector(smap.constants.prototype.POPUP_INNER);
        var popupContentWrapper = wrapperDiv.querySelector('.s-map-popup-inner');
        var popupLayerHeader = container.querySelector('.s-map-popup-header');
        popupLayerHeader.innerHTML = header;

        var elementDiv = document.createElement('div');
        elementDiv.setAttribute('class', 's-map-popup-content');
        elementDiv.setAttribute('role', 'group');

        elementDiv.innerHTML = fullContent;

        popupContentWrapper.appendChild(elementDiv);

    },
    // if feature is single
    singlePopup: function (feature, target) {

        smap.variables.container.classList = "popup";

        var wrapperDiv = document.querySelector('.' + target);
        var container = wrapperDiv.querySelector(smap.constants.prototype.POPUP_INNER);
        var popupContentWrapper = wrapperDiv.querySelector('.s-map-popup-inner');
        var popupLayerHeader = container.querySelector('.s-map-popup-header');
        var header = feature.get('header');

        if (!header) {
            header = feature.source.get('name') || '';
        }

        popupLayerHeader.innerHTML = header;

        var elementDiv = document.createElement('div');
        elementDiv.setAttribute('class', 's-map-popup-content');
        elementDiv.setAttribute('role', 'group');

        if (feature.get('fullContent')) {
            elementDiv.innerHTML = feature.get('fullContent');
        } else {

            // if not exist remove, if exists populate
            if (feature.get('image')) {

                var elementFigure = document.createElement('figure');

                var elementImg = document.createElement('img');
                elementImg.src = feature.get('image');
                elementImg.setAttribute('alt', '');

                elementFigure.appendChild(elementImg);

                elementDiv.appendChild(elementFigure);
            }
            // if not exist remove, if exists populate
            if (feature.get('name')) {

                var elementHeader = document.createElement('span');
                elementHeader.setAttribute('class', 'title');
                elementHeader.innerHTML = feature.get('name');

                elementDiv.appendChild(elementHeader);
            }
            // if not exist remove, if exists populate
            if (feature.get('content')) {
                var elementText = document.createElement('p');
                elementText.setAttribute('class', 'preamble');
                elementText.innerHTML = feature.get('content');

                elementDiv.appendChild(elementText);
            }
        }

        // if not exist remove, if exists populate
        if (feature.get('url')) {

            var elementUrl = document.createElement('a');
            elementUrl.setAttribute('class', 'link');
            elementUrl.href = feature.get('url');

            elementUrl.appendChild(elementDiv);

            popupContentWrapper.appendChild(elementUrl);

        }  else {
            popupContentWrapper.appendChild(elementDiv);
        }

        if (smap.variables.config.modify) {

            var navLinks = document.createElement('nav');
            navLinks.setAttribute('class', 'nav-links');

            if (smap.variables.config.modify.customButton) {

                smap.variables.config.modify.customButton.forEach(function(buttonObj) {

                    if (feature.getId() && (buttonObj.modifyRemove || buttonObj.modifyEvent)) {

                        var buttonLink = document.createElement('a');

                        buttonLink.setAttribute('href', 'javascript:;');
                        (buttonObj.class) ? buttonLink.setAttribute('class', buttonObj.class) : null;
                        (buttonObj.id) ? buttonLink.setAttribute('id', buttonObj.id) : null;
                        buttonLink.setAttribute('feature-id', feature.getId());
                        (buttonObj.name) ? buttonLink.innerHTML = buttonObj.name : null;
                        buttonLink.featureData = feature;

                        (buttonObj.modifyRemove) ? buttonLink.classList += ' s-map-popup-delete': null;
                        (buttonObj.modifyEvent) ? buttonLink.classList += ' s-map-popup-change': null;

                        navLinks.appendChild(buttonLink);

                    }

                })
            }

            if (popupContentWrapper.querySelector('.nav-links')) {
                popupContentWrapper.querySelector('.nav-links').remove();
                popupContentWrapper.appendChild(navLinks);
            } else {
                popupContentWrapper.appendChild(navLinks);
            }
        }
    },

    // if feature is added or edited
    addOrModifyPopup: function (feature, target, me) {

            smap.variables.container.classList = "popup-edit";

            var wrapperDiv = document.querySelector('.' + target);
            var container = wrapperDiv.querySelector(smap.constants.prototype.POPUP_INNER);
            var popupContentWrapper = wrapperDiv.querySelector('.s-map-popup-inner');
            var popupLayerHeader = container.querySelector('.s-map-popup-header');

            (!!feature.get('header')) ? popupLayerHeader.innerHTML = feature.get('header') :
            (!!layer.getSource().get('name')) ? popupLayerHeader.innerHTML = layer.getSource().get('name') : popupLayerHeader.innerHTML = 'Lägg till intressepunkt' ;

            var elementDiv = document.createElement('div');
            elementDiv.setAttribute('class', 's-map-popup-content');
            elementDiv.setAttribute('role', 'group');

                var elementLayer = document.createElement('select');
                elementLayer.setAttribute('class', 'poi-layer');
                elementLayer.setAttribute('id', 'poi-layer');


                me.variables.featureArray.forEach(function(obj, i) {
                    var elementOption = document.createElement('option');
                    elementOption.setAttribute('value', obj.get('name'));
                    elementOption.innerHTML = obj.get('name');

                    elementLayer.appendChild(elementOption);
                })

                var elementLayerLabel = document.createElement('label');
                elementLayerLabel.setAttribute('for', 'poi-layer');
                elementLayerLabel.innerHTML = 'Kartlager';

                elementDiv.appendChild(elementLayerLabel);
                elementDiv.appendChild(elementLayer);


                    var elementSelect = document.createElement('select');
                    elementSelect.setAttribute('class', 'poi-type-form');
                    elementSelect.setAttribute('class', 'poi-type');
                    elementSelect.setAttribute('id', 'poi-type');


                    for (var i = 1; i < 9; i++) {
                        var elementOption = document.createElement('option');
                        elementOption.setAttribute('value', i);
                        elementOption.innerHTML = i;
                        elementSelect.appendChild(elementOption);
                    }
                    elementDiv.appendChild(elementSelect);

                    // if not exist remove, if exists populate
                    var elementHeader = document.createElement('input');
                    elementHeader.setAttribute('type', 'text');
                    elementHeader.setAttribute('class', 'poi-title-form');

                    var elementSelectLabel = document.createElement('label');
                    elementSelectLabel.setAttribute('for', 'poi-type');
                    elementSelectLabel.innerHTML = 'Intressepunkt typ';

                    elementDiv.appendChild(elementSelectLabel);
                    elementDiv.appendChild(elementSelect);

                // if not exist remove, if exists populate

                    var elementHeader = document.createElement('input');
                    elementHeader.setAttribute('class', 'poi-title-form');
                    elementHeader.setAttribute('id', 'poi-title');
                    elementHeader.setAttribute('type', 'text');
                    elementHeader.setAttribute('placeholder', 'Rubrik');
                    (feature.get('name')) ? elementHeader.value = feature.get('name') : null;

                    var elementHeaderLabel = document.createElement('label');
                    elementHeaderLabel.setAttribute('for', 'title');
                    elementHeaderLabel.innerHTML = 'Rubrik';

                    elementDiv.appendChild(elementHeaderLabel);
                    elementDiv.appendChild(elementHeader);

                    // if not exist remove, if exists populate
                    var elementText = document.createElement('textarea');
                    elementText.setAttribute('rows', '5');
                    elementText.setAttribute('class', 'preamble-form');
                    elementText.setAttribute('placeholder', 'Text');
                    elementText.setAttribute('id', 'poi-preamble');
                    elementText.setAttribute('placeholder', 'Text');
                    (feature.get('content')) ? elementText.innerHTML = feature.get('content') : null;

                    var elementTextLabel = document.createElement('label');
                    elementTextLabel.setAttribute('for', 'poi-preamble');
                    elementTextLabel.innerHTML = 'Text';

                    elementDiv.appendChild(elementTextLabel);
                    elementDiv.appendChild(elementText);

                    // if not exist remove, if exists populate
                    var elementUrl = document.createElement('input');
                    elementUrl.setAttribute('type', 'text');
                    elementUrl.setAttribute('id', 'text');
                    elementUrl.setAttribute('class', 'poi-link-form');
                    elementUrl.setAttribute('placeholder', 'Länk');
                    (feature.get('url')) ? elementUrl.value = feature.get('url') : null;

                    var elementUrlLabel = document.createElement('label');
                    elementUrlLabel.setAttribute('for', 'text');
                    elementUrlLabel.innerHTML = 'Länk';

                    (smap.variables.config.modify.modifyData) ? (elementDiv.appendChild(elementUrlLabel), elementDiv.appendChild(elementUrl)) : null;

                    var elementImgData;
                    elementImgData = document.createElement('input');
                    elementImgData.setAttribute('type', 'text');
                    (feature.get('image')) ? elementImgData.innerHTML = feature.get('image') : null;
                    elementImgData.setAttribute('class', 'poi-img-form');
                    elementImgData.setAttribute('id', 'img');
                    elementImgData.setAttribute('placeholder', 'Bild, 64bit kod');

                    var elementImgDataLabel = document.createElement('label');
                    elementImgDataLabel.setAttribute('for', 'img');
                    elementImgDataLabel.innerHTML = 'Bild';

                    elementDiv.appendChild(elementImgDataLabel);
                    elementDiv.appendChild(elementImgData);

                popupContentWrapper.appendChild(elementDiv);

            if (smap.variables.config.modify) {

                var navLinks = document.createElement('nav');
                navLinks.setAttribute('class', 'nav-links');

                if (smap.variables.config.modify.customButton) {

                    smap.variables.config.modify.customButton.forEach(function(buttonObj) {

                        var buttonLink = document.createElement('a');

                        buttonLink.setAttribute('href', 'javascript:;');
                        (buttonObj.class) ? buttonLink.setAttribute('class', buttonObj.class) : null;
                        (buttonObj.id) ? buttonLink.setAttribute('id', buttonObj.id) : null;
                        buttonLink.setAttribute('feature-id', feature.getId());
                        (buttonObj.name) ? buttonLink.innerHTML = buttonObj.name : null;
                        buttonLink.featureData = feature;

                        (buttonObj.modifyData) ? buttonLink.classList += ' s-map-popup-modify': null;
                        (buttonObj.saveData) ? buttonLink.classList += ' s-map-popup-save': null;
                        (buttonObj.modifyEvent) ? buttonLink.classList += ' s-map-popup-change': null;
                        (typeof(feature.getId()) === 'string') ? (buttonObj.modifyRemove) ? buttonLink.classList += ' s-map-popup-delete': null : null;

                        (typeof(feature.getId()) === 'string') ?
                            (buttonObj.modifyRemove) ? navLinks.appendChild(buttonLink) : navLinks.appendChild(buttonLink) :
                            (buttonObj.modifyRemove) ? null : navLinks.appendChild(buttonLink);

                    })
                }

                if (popupContentWrapper.querySelector('.nav-links')) {
                    popupContentWrapper.querySelector('.nav-links').remove();
                    popupContentWrapper.appendChild(navLinks);
                } else {
                    popupContentWrapper.appendChild(navLinks);
                }
            }
        },

    /* END: CREATE MULTI AND SINGEL POPUP */

    /* START: RESET DATA IN POPUP */

    // empty data in popup.
    reSetPopoverContent: function (target) {

        var wrapperDiv = document.querySelector('.' + target);
        var container = wrapperDiv.querySelector(smap.constants.prototype.POPUP_INNER);

        // check if exists remove if it does
        (container.querySelector('.link')) ? container.querySelector('.link').parentNode.removeChild(container.querySelector('.link')) : null;
        (container.querySelector('.title')) ? container.querySelector('.title').parentNode.removeChild(container.querySelector('.title')) : null;
        (container.querySelector('.preamble')) ? container.querySelector('.preamble').parentNode.removeChild(container.querySelector('.preamble')) : null;
        (container.getElementsByTagName('figure')[0]) ? container.getElementsByTagName('figure')[0].parentNode.removeChild(container.getElementsByTagName('figure')[0]) : null;
        (container.getElementsByTagName('nav')[0]) ? container.getElementsByTagName('nav')[0].parentNode.removeChild(container.getElementsByTagName('nav')[0]) : null;
        (container.querySelector('.s-map-popup-content')) ? container.querySelector('.s-map-popup-content').parentNode.removeChild(container.querySelector('.s-map-popup-content')) : null;
    },

    addToolButton: function (config, target, me) {

    config.forEach(function(obj, i) {

        if (obj.tool === 'pointAdd' || obj.tool === 'placePoi') {

                var addButton = document.createElement('button');
                addButton.setAttribute('type','button');
                addButton.setAttribute('class','s-map-btn');

                var addButtonI = document.createElement('i');
                addButtonI.setAttribute('aria-hidden','true');
                addButtonI.setAttribute('class','icon icon-map-pin-light');

                var addButtonSpan = document.createElement('span');
                addButtonSpan.innerHTML = obj.name;

                addButton.appendChild(addButtonI);
                addButton.appendChild(addButtonSpan);

                mapStruct.prototype.addEvent(addButton, 'click', function (e) {
                    e.stopPropagation();
                    e.preventDefault();
                    me.advancedMap.toolFunnel(obj.tool, me, addButton); });

                me.variables.wrapperDiv.querySelector('.s-map-panel-nav').appendChild(addButton);

        } else {

            var wrappElement = document.createElement('button');
            wrappElement.setAttribute('class', 's-switch');
            wrappElement.setAttribute('type', 'button');
            wrappElement.setAttribute('role', 'checkbox');

                var labelElement = document.createElement('label');
                labelElement.setAttribute('for', 's-map-switch-' + obj.id + '-' + i);
                labelElement.setAttribute('role', 'checkbox');
                labelElement.setAttribute('class', 'icon ' + obj.class);

                    var spanElement = document.createElement('span');
                    spanElement.setAttribute('id', 's-map-switch-label-' + obj.id + '-' + i);
                    spanElement.innerHTML = obj.name;

                    var inputElement = document.createElement('input');
                    inputElement.setAttribute('type', 'checkbox');
                    inputElement.setAttribute('id', 's-map-switch-' + obj.id + '-' + i);
                    inputElement.setAttribute('name', obj.id + '-' + i);
                    inputElement.setAttribute('value', 1);

                    var iElement = document.createElement('i');
                    iElement.setAttribute('class', 'cluster-link');

                labelElement.appendChild(spanElement);
                labelElement.appendChild(inputElement);
                labelElement.appendChild(iElement);

            wrappElement.appendChild(labelElement);

            target.appendChild(wrappElement);

            mapStruct.prototype.addEvent(inputElement, 'change', function () { me.advancedMap.toolFunnel(obj.tool, me, labelElement); });

        }
    })

    },

    /* END: RESET DATA IN POPUP */

     /* START: CREATE SEARCH */
     createSearchBar: function (target) {
        var smapSearchDiv = document.createElement('div');
        smapSearchDiv.setAttribute('class', 's-map-search-autocomplete');
        target.prepend(smapSearchDiv);

        var formElement = document.createElement('form');
        formElement.setAttribute('id', 's-map-search-form');
        formElement.setAttribute('onsubmit', 'return false;');
        smapSearchDiv.appendChild(formElement);

        var labelElement = document.createElement('label');
        labelElement.setAttribute('for', 's-map-search-input')
        labelElement.setAttribute('class', 'visuallyhidden');
        labelElement.setAttribute('id', 's-map-search-input-label');
        labelElement.innerHTML = "Sök adress och välj sedan från listan nedan med hjälp av piltangenterna";
        formElement.appendChild(labelElement);

        var inputElement = document.createElement('input');
        inputElement.setAttribute('type', 'search');
        inputElement.setAttribute('id', 's-map-search-input');
        inputElement.setAttribute('name', 's-map-search-input');
        inputElement.setAttribute('placeholder', 'Sök adress i karta');
        inputElement.setAttribute('class', 'form-control');
        inputElement.setAttribute('autocomplete', 'off');
        inputElement.setAttribute('spellcheck', 'false');
        inputElement.setAttribute('role', 'combobox');
        inputElement.setAttribute('aria-expanded', 'false');
        inputElement.setAttribute('aria-owns', 's-map-search-autocomplete');
        inputElement.setAttribute('aria-autocomplete', 'list');
        inputElement.setAttribute('aria-labelledby', 's-map-search-input-label');
        formElement.appendChild(inputElement);

        var theBeardDiv = document.createElement('div');
        theBeardDiv.setAttribute('id', 's-map-search-autocomplete');
        theBeardDiv.setAttribute('class', 's-map-autocomplete hide');
        theBeardDiv.setAttribute('role', 'listbox');
        theBeardDiv.setAttribute('aria-labelledby', 's-map-search-input-label');
        formElement.appendChild(theBeardDiv);

        var buttonElement = document.createElement('button');
        buttonElement.setAttribute('type', 'submit');
        buttonElement.setAttribute('id', 's-map-search-button');
        buttonElement.setAttribute('class', 's-map-btn icon-search-light block-icon s-map-search-button');
        buttonElement.setAttribute('aria-label', 'Sök karta');
        formElement.appendChild(buttonElement);

        var buttonSpanElement = document.createElement('span');
        buttonSpanElement.setAttribute('class', 'visuallyhidden');
        buttonSpanElement.innerHTML="Sök karta";
        buttonElement.appendChild(buttonSpanElement);

        var resetButton = document.createElement('button');
        resetButton.setAttribute('type', 'button');
        resetButton.setAttribute('class', 's-map-search-reset hide');
        resetButton.setAttribute('aria-label', 'Rensa sökning');
        formElement.appendChild(resetButton);

        var resetButtonSpan = document.createElement('span');
        resetButtonSpan.setAttribute('class', 'visuallyhidden');
        resetButtonSpan.innerHTML="Rensa sökning";
        resetButton.appendChild(resetButtonSpan);
    },
    /* END: CREATE SEARCH */

     createNoResultBeard(target, me){
        target.innerHTML = "";

        var div = document.createElement('div');
        div.setAttribute('class', 's-map-autocomplete-no-hits');
        target.appendChild(div);

        var span = document.createElement('span');
        span.innerHTML = "Ingen träff";
        div.appendChild(span);
     },
     /* START: CREATE SEARCH RESULT BEARD */
     createSearchResultBeard: function (target, data, callback) {
        target.innerHTML = "";

        data.forEach((streetNameSuggestion,i) => {
            var div = document.createElement('div');
            target.appendChild(div);

            var button = document.createElement('button');
            button.setAttribute('class', 'search-result-item');
            button.setAttribute('role', 'option');
            button.setAttribute('data-value', streetNameSuggestion);
            if (i == 0){
                button.classList.add('arrow-down-to');
            }
            button.onclick = callback;
            button.addEventListener("keydown", function(e) {
                let current = e.currentTarget;
                switch(e.key) {
                    case "Enter":
                        // Cancel the default action, if needed
                        e.preventDefault();
                        callback(e);
                        break;
                    case "ArrowDown":
                        e.preventDefault();
                        var nextItemDiv = e.currentTarget.parentNode.nextSibling;
                        if (nextItemDiv != null){
                            var nextItem = nextItemDiv.firstChild;
                            current.classList.remove("s-map-search-list-selected")
                            nextItem.classList.add("s-map-search-list-selected");
                            nextItem.focus();
                        }
                        break;
                    case "ArrowUp":
                        e.preventDefault();
                        var nextItemDiv = e.currentTarget.parentNode.previousSibling;
                        if (nextItemDiv != null){
                            var nextItem = nextItemDiv.firstChild;
                            current.classList.remove("s-map-search-list-selected")
                            nextItem.classList.add("s-map-search-list-selected");
                            nextItem.focus();
                        }
                        break;
                    }
            });
            div.appendChild(button);
            target.addEventListener("keydown", function(e) {
                let current = e.currentTarget;
                switch(e.key) {
                    case "ArrowUp":
                        e.preventDefault();
                        break;
                    case "ArrowDown":
                        e.preventDefault();
                        break;
                }
            });
            var span = document.createElement('span');
            span.innerHTML = streetNameSuggestion;
            button.appendChild(span);
        });

     },

    /* END: CREATE SEARCH RESULT BEARD */

    /* START: CREATE EVENT HANDELERS */

    // // add click event
    addEvent: function (el, type, handler) {
        el.addEventListener(type, handler);
    },

    // // remove click event
    removeEvent: function (el, type, handler) {
        el.removeEventListener(type, handler);
    },

    /* END: CREATE EVENT HANDELERS */

    };

    // function to export functions
    return mapStruct.prototype;
}
