function sortData(smap) {

    sortData.prototype = {

        /* START: SORT LAYERS FOR MAP AND LAYER TOGGLE */

        // return roeted array with not backgroundlayers on 'top' and sorted layers under.
        // takes array of objects
        sortLayers: function (layers, me) {

            // separate background layers from 'normal layers'
            var layersArrayBackground = layers.filter(function (x) {
                return x.placement.backgroundlayer;
            });
            var layersArrayNormal = layers.filter(function (x) {
                return !x.placement.backgroundlayer;
            });
            var sortedBackgroundArray = me.basicMap.sortBackgroundLayers(layersArrayBackground);

            var layersArray = [];
            // put background layers in the bottom of the array
            sortedBackgroundArray.forEach(function (obj) {
                layersArray = layersArray.concat(obj);
            });

            layersArray = layersArray.concat(layersArrayNormal);

            return layersArray;
        },

        // sort backgroundlayers based on backgroundlayersname
        // takes array of objects
        sortBackgroundLayers: function (array) {
            var cat = [];

            array.forEach(function (obj) {
                if (cat.indexOf(obj.placement.backgroundlayername) === -1) {
                    cat.push(obj.placement.backgroundlayername);
                }
            });

            var filterArray = [];
            cat.forEach(function (catname) {
                var catObj = [];
                array.forEach(function (obj) {
                    (obj.placement.backgroundlayername === catname) ? catObj.push(obj) : null;
                });
                filterArray.push(catObj);
            });

            filterArray.sort(function (a) {
                return a.length;
            });

            filterArray.sort(function (a, b) {
                return a[0].placement.order - b[0].placement.order;
            });

            //filterArray.reverse();
            return filterArray;

        },


        /* START: CREATE LAYERS AND SEND TO CREATE LAYER TOGGLE  */

    };

    return sortData.prototype;
}