function createInteraction(smap) {

    createInteraction.prototype = {

        // function for when a feature is clicked in the map.
        mapclick: function(evt, map, overlay, target, me) {
            // if clicked position on map has feature
       if (map.hasFeatureAtPixel(map.getPixelFromCoordinate(evt.coordinate))) {
                me.advancedMap.windowWidth(target);
                smap.basicMap.resizeMap(smap);

                if (window.innerWidth < 480) {
                    setTimeout(function() {
                        me.advancedMap.funnelMapclick(evt, map, overlay, target, true, me);
                    }, 120);
                } else {
                    me.advancedMap.funnelMapclick(evt, map, overlay, target, true, me);
                }
            } else if (me.variables.latestDrawFeature) {
                if(me.variables.tools['placePoi']) {
                    smap.structureMap.reSetPopoverContent(smap.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), smap);
                    smap.variables.overlay.setOffset([0,-10]);
                    smap.variables.overlay.setPosition(smap.variables.latestDrawFeature.getGeometry().getCoordinates());
                    smap.structureMap.addOrModifyPopup(smap.variables.latestDrawFeature, smap.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), smap);
                } else if (me.variables.tools['pointAdd']) {
                    smap.event.add(smap.variables.latestDrawFeature.getGeometry());
                }
            } else {
                // remote check any WMS layers with popup enabled
                me.advancedMap.doWMSFeatureCheck(evt, map, overlay, target, me, function (feature) {

                    me.advancedMap.windowWidth(target);
                    smap.basicMap.resizeMap(smap);

                    // in case of auto fullscreen on a small screen, wait for until that is complete before showing the popup
                    setTimeout(function () {
                        smap.variables.overlay.setOffset([-1, -12]);
                        smap.basicMap.moveToPosition(smap, feature.getGeometry().getCoordinates(), 150, smap.variables.singleZoom, function(complete) {
                            complete && overlay.setPosition(feature.getGeometry().getCoordinates());
                            smap.structureMap.reSetPopoverContent(target, smap);
                            smap.structureMap.singlePopup(feature, target);
                        });
                    }, window.innerWidth < 480 ? 120 : 0);
                
                });
            }
        },

        doWMSFeatureCheck: function (evt, map, overlay, target, me, callback) {
            var layers = me.variables.clickableWMS;

            function checkLayer(i) {

                if (layers.length === i) {
                    return;
                }

                //If layer not visible, continue to check next one
                if (!layers[i].getVisible()){
                    checkLayer(i+1);
                }

                var layer = layers[i],
                    source = layer.getSource(),
                    url = source.getGetFeatureInfoUrl(
                        evt.coordinate,
                        map.getView().getResolution(),
                        'EPSG:3011',
                        { 'INFO_FORMAT': 'application/json' }
                    );

                me.advancedMap.request(url, function (error, result) {
                    if (result.features && result.features.length > 0)  {
                        var data = result.features[0],
                            template = source.get('template'),
                            feature = new ol.Feature({
                                geometry: new ol.geom.Point(data.geometry.type=="Point" ? data.geometry.coordinates : evt.coordinate),
                                header: source.get('name') || '',
                                fullContent: template(data.properties)
                            });

                        feature.setId(data.id);

                        callback(feature, layer);
                    } else {
                        checkLayer(i+1);
                    }
                });
            }

            checkLayer(0);
        },
        funnelMapclick: function(evt, map, overlay, target, bool, me) {

            map.forEachFeatureAtPixel(map.getPixelFromCoordinate(evt.coordinate), function (feature) {
                smap.variables.overlay.setOffset([0,-35]);

                if (feature.get('features') || feature.getId()) {
                    if (feature.getProperties().type !== 'GeolocationPosition' || feature.getProperties().type !== 'SearchLocationPosition' || !!bool) {
                        overlay.setPosition(undefined);
                        if (smap.variables.singleZoom != null) {
                            // set position
                            function setposition(complete){
                                (complete ) ? overlay.setPosition(feature.getGeometry().getCoordinates()) : null;
                            }
                            // move to position
                            if (feature.get('features')) {
                                if (feature.get('features').length > smap.variables.clusterZoomFeatureAmount) {
                                    smap.basicMap.moveToPosition(smap, feature.getGeometry().getCoordinates(), 150, me.basicMap.getZoom(me) + 1, function(/*complete*/){});
                                } else if (feature.get('features').length > 1) {
                                    smap.basicMap.moveToPosition(smap, feature.getGeometry().getCoordinates(), 150, smap.basicMap.getZoom(smap), setposition);
                                } else {
                                    smap.basicMap.moveToPosition(smap, feature.getGeometry().getCoordinates(), 150, smap.variables.singleZoom, setposition);
                                }
                            } else {
                                smap.basicMap.moveToPosition(smap, feature.getGeometry().getCoordinates(), 150, smap.variables.singleZoom, setposition);
                            }
                        } else {
                            // set position of popup on map
                            overlay.setPosition(feature.getGeometry().getCoordinates());
                        }
                    }
                }
                //TODO: set search result position to null
                if (feature.getProperties().type === 'GeolocationPosition') {
                    smap.variables.positionFeature.setGeometry(null);

                } else if (feature.getProperties().type === 'SearchLocationPosition') {
                    smap.variables.searchPositionFeature.setGeometry(null);
                    smap.basicMap.resetSearch(me);
                    var resetDiv = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_RESET_CLASS);
                    var input = me.variables.wrapperDiv.parentNode.querySelector(me.constants.prototype.SEARCH_TEXT_INPUT_ID);
                    resetDiv.classList.add('hide');
                    input.value = "";
                } 
                else if (feature.getId() || feature.get('features')) {
                    me.advancedMap.activatePopup(feature, map, overlay, target);
                } else if (smap.variables.tools['placePoi']) {
                    smap.structureMap.reSetPopoverContent(smap.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), smap);
                    smap.variables.overlay.setOffset([0,-10]);
                    smap.variables.overlay.setPosition(smap.variables.latestDrawFeature.getGeometry().getCoordinates());
                    smap.structureMap.addOrModifyPopup(smap.variables.latestDrawFeature, smap.constants.prototype.EVENT_PREFIX_WRAPPER.substring(1), smap);
                }
            });
        },

        movePositionOfPois: function() {
            smap.variables.mapArray.forEach(function (layer) {
                if (layer.type === 'VECTOR' && !layer.isCombinedClusterLayer) {
                    var source = layer.cluster
                        ? layer.getSource().getSource()
                        : layer.getSource();

                    smap.variables.snap = new ol.interaction.Snap({ source: source });
                    smap.variables.map.addInteraction(smap.variables.snap);

                    var modify = new ol.interaction.Modify({ source: source });
                    smap.variables.map.addInteraction(modify);

                    smap.variables.modify.push(modify);

                    source.on('removefeature', function(ev) {
                        smap.event.delete(ev);
                    });
                }
            })
        },

        // call this function once with either an array of pois or a single poi, create pois replaces current pois in the layer with new incoming pois from data
        createPois: function (layer, data) {
            createInteraction.prototype.addPois(layer, data, true);
        },

        // call this function once with either an array of pois or a single poi, if function is called many times, very fast
        // it tends to break because it is not the right way to add pois.
        addPois: function(layer, data, clearData) {
            var layers = smap.variables.map.getLayers().getArray(),
                geofeatures,
                source;

            if (typeof layer === 'string') {
                layers.some(function (l) {
                    if (l.get('name') === layer) {
                        layer = l;
                        return true;
                    }
                });
            } else {
                layer = layer || layers[layers.length - 1];
            }

            data = data || [];
            source = layer.cluster
                ? layer.getSource().getSource()
                : layer.getSource();

            geofeatures = data.map(function (obj, i) {
                obj.properties.geometry = new ol.geom.Point(obj.geometry.coordinates);
                
                var feature = new ol.Feature(obj.properties);
                feature.setId('poi_' + i);
                feature.source = source;

                return feature;
            });

            if (clearData) {
                source.clear(true);
            }

            source.addFeatures(geofeatures);
        },

        // refresh popup pois when toggling layers or zooming
        refreshPopupOnAction: function(smap, overlay, target) {
            var map = smap.variables.map;

            if (overlay.getPosition()) {
                map.forEachFeatureAtPixel(map.getPixelFromCoordinate(overlay.getPosition()), function (feature, layer) {
                    smap.variables.overlay.setOffset([0,-35]);

                    if (feature.getId()) {
                        if (feature.getProperties().type !== 'GeolocationPosition') {
                            overlay.setPosition(undefined);

                            if (smap.variables.singleZoom != null) {
                                overlay.setPosition(feature.getGeometry().getCoordinates());
                            }
                        }
                    }

                    if (feature.getProperties().type === 'GeolocationPosition') {
                        smap.variables.positionFeature.setGeometry(null);
                    } else {
                        smap.advancedMap.activatePopup(feature, map, overlay, target);
                    }
                });
            }
        },

        request: function (url, callback) {
            var xhr = new XMLHttpRequest();

            xhr.open('GET', url, true);
            xhr.onerror = function () {
                callback(xhr);
            };
            xhr.onload = function() {
                if (xhr.status >= 200 && xhr.status < 300) {
                    callback(null, JSON.parse(xhr.responseText));
                } else {
                    callback(xhr);
                }
            };

            xhr.send();
        }
    };

    return createInteraction.prototype;
}
