function createTools(smap) {

    createTools.prototype = {

        createTool: function(tool, me) { 
        
            var source = (!!me.variables.drawLayer) ? null : new ol.source.Vector({wrapX: false});
            
            var vector = (!!me.variables.drawLayer) ? null : new ol.layer.Vector({
                    source: source,
                    style: new ol.style.Style({
                        fill: new ol.style.Fill({
                            color: [196, 0, 100, 0.25]
                        }),
                        stroke: new ol.style.Stroke({
                            color: [196, 0, 100, 1]
                        }),
                        zIndex: Infinity
                    }),
                    zIndex: 999
            });

            (vector) ? vector['drawLayer'] = true : null;
            (!!me.variables.drawLayer) ? null : me.variables.map.addLayer(vector);
            (!!me.variables.drawLayer) ? null : me.variables.drawLayer = vector;

            var draw = new ol.interaction.Draw({
                source: (me.variables.drawLayer) ? me.variables.drawLayer.getSource() : source,
                type: (tool === 'placePoi') ? 'Point' : (tool === 'pointAdd') ? 'Point' : tool,
            });

            me.variables.tools[tool] = draw;
            me.variables.map.addInteraction(draw);

            me.variables.drawLayer.setVisible(true);

            me.variables.drawLayer.getSource().on('addfeature', function(ev) {
                smap.variables.latestDrawFeature = ev.feature;
            })

            var check = (tool === 'placePoi') ? false : (tool === 'pointAdd') ? false : true;
            if (check) {
                draw.on('drawend', function (event) {
                    smap.event.drawend(event);
                });
            }
        },

        toolFunnel: function(tool, me, obj) {
            if (obj.getAttribute('type') === 'button') {
                if (obj.classList.contains('s-map-btn-active')) {
                    me.variables.map.removeInteraction(me.variables.tools[tool]);
                    delete me.variables.tools[tool];
                    (toolInArray === 'placePoi') ? me.variables.overlay.setPosition(undefined) : null ;
                } else {
                    for (var toolInArray in me.variables.tools) {
                        (toolInArray === 'placePoi') ? me.variables.overlay.setPosition(undefined) : null ;
                        me.variables.map.removeInteraction(me.variables.tools[toolInArray]);
                        delete me.variables.tools[toolInArray];
                    }
                    me.advancedMap.createTool(tool, me);
                    var toolPanel = me.variables.wrapperDiv.querySelector('.s-map-panel-tools');
                    var switchButtons = toolPanel.querySelectorAll('.s-switch');
                    for (var x = 0; x < switchButtons.length; x++) {
                        switchButtons[x].querySelector('input').checked = false;
                    }
                }
                obj.classList.toggle('s-map-btn-active');
                obj.querySelector('i').classList.toggle('icon-map-pin');
                obj.querySelector('i').classList.toggle('icon-map-pin-light');
            } else if (obj.querySelector('#' + obj.getAttribute('for')).checked) {
                for (var toolInArray in me.variables.tools) {
                    (toolInArray === 'placePoi') ? me.variables.overlay.setPosition(undefined) : null ;
                    me.variables.map.removeInteraction(me.variables.tools[toolInArray]);
                    delete me.variables.tools[toolInArray];
                }
        
                me.advancedMap.createTool(tool, me);
        
                var toolPanel = me.variables.wrapperDiv.querySelector('.s-map-panel-tools');
                var switchButtons = toolPanel.querySelectorAll('.s-switch');
                for (var x = 0; x < switchButtons.length; x++) {
                    switchButtons[x].querySelector('input').checked = false;
                }
                obj.querySelector('#' + obj.getAttribute('for')).checked = true;
            } else {
                me.variables.map.removeInteraction(me.variables.tools[tool]);
                delete me.variables.tools[tool];
            }
        }
    }

    return createTools.prototype;

};